import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import BootstrapTable from "react-bootstrap-table-next";
import {
  smallTableSelectRow,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
  PleaseWaitMessage, NoRecordsFoundMessage
} from "../../../_metronic/_helpers";
import { Modal } from "react-bootstrap";
import { clearEditReport, requestReports, updateReportUsers } from '../../../redux/reports-reducer';
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import * as uiHelpers from "../../Events/EventsUIHelpers";

function UsersList(props) {
  let [ids, setIds] = useState(props.ids);

  const [isLoading, setLoading] = useState(false);
  const [tablePage, setTablePage] = useState(1);
  // const [error, setError] = useState(false);

  const { entities, users, reportId } = useSelector(
    (state) => ({
      users: state.customers.ak_users,
      entities: state.customers.ak_users.filter(e => e.system_role === 2),
      reportId: state.reports.ak_selected_report.report_uuid,
    }),
    shallowEqual
  );

  // Getting current state of user list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );

  const { listLoading } = currentState;

  const columns = [
    /*{
      dataField: "uuid",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },*/
    {
      dataField: "first_name",
      text: "Имя",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "last_name",
      text: "Фамилия",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    }
  ]

  const dispatch = useDispatch();

  const onSaveClicked = () => {
    setLoading(true);
    dispatch(updateReportUsers(reportId, ids))
      .then(() => {
        // .then(() => {
        setLoading(false);
        props.onHide();
        dispatch(clearEditReport());
        dispatch(requestReports());
        // })
      })
      .catch(() => {
        setLoading(false);
      })
  }

  const onCloseClicked = () => {
    dispatch(clearEditReport());
    props.onHide();
  }

  const setSpeakersIds = (newSpeakers) => {
    setIds(newSpeakers);
  }

  const PageChange = (page) => {
    setTablePage(page);
  }

  // check table page
  const checkTablePage = () => {
    if (tablePage > Math.ceil(entities.length / 10) && tablePage > 1) {
      setTablePage(tablePage - 1);
      return tablePage - 1;
    }

    return tablePage;
  }

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: entities.length,
    //sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: 10,
    page: checkTablePage(),
    onPageChange: PageChange
  };

  // console.clear();
  //console.log('currentState', currentState);
  //console.log('entities', entities);
  //console.log('ids', ids);
  // return null;

  return (
    <>
      <Modal.Body>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  keyField="uuid"
                  data={entities === null ? [] : entities}
                  columns={columns}
                  selectRow={smallTableSelectRow(ids, setSpeakersIds, entities)}
                  defaultSorted={uiHelpers.defaultSorted}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={entities} />
                  <NoRecordsFoundMessage entities={entities} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </Modal.Body>
      <Modal.Footer>
        {/* {error &&
          <div
            className="alert alert-custom alert-light-warning fade show p-2"
            role="alert"
            style={{height: '38px'}}
          >
            <span className="svg-icon svg-icon-white pr-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} />{" "}
            </span>
            <div className="alert-text font-weight-bold">
              Нельзя назначить более 3-х спикеров на доклад
            </div>
          </div>
        } */}
        <button
          type="button"
          onClick={onCloseClicked}
          className="btn btn-light btn-elevate"
        >
          Отменить
        </button>
        <button
          type="submit"
          onClick={() => onSaveClicked()}
          className="btn btn-primary btn-elevate"
          disabled={isLoading}
        >
          Сохранить
        </button>
      </Modal.Footer>
    </>
  )
}

export default UsersList;
