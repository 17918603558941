import React, { useRef } from "react";
import { Card, CardBody } from "../../_metronic/_partials/controls";
const alignCenter = {
    paddingTop: '64%',
};



export function Webgl() {
    const webglRef = useRef();

    let setToken = () => {
        webglRef.current.contentWindow.postMessage(JSON.stringify({
            key: 'token',
            data: localStorage.token
        }), "*");
    }

    return (
        <Card style={{ width: '100%', height: '81vh' }}>
            <CardBody style={{ width: '100%', height: '81vh', padding: 16 }}>
                <div className="embed-responsive" style={alignCenter}>

                    <iframe ref={webglRef}
                        className="embed-responsive-item"
                        src="https://s-dt2.cloud.gcore.lu/viexpo-webgl/axoft/unity_client/index.html"
                        title="Axoft Build"
                        allowFullScreen
                        onLoad={setToken}
                    ></iframe>
                </div>
            </CardBody>
        </Card>
    );
}
