export * from "./XLXExport";

export const getIntFromBool = (boolValue) =>{
    return Number(boolValue);
}

export const getBoolFromString = (stringValue) => {
    if (stringValue === "-1") {
        return null
    }
    if(typeof stringValue === "boolean"){
        return stringValue;
    }
    return stringValue === "1";
}

export const getStringFromBool = (boolValue) => {
    if(typeof boolValue === "boolean"){
        return boolValue ? "1" : "0";
    }
    else return boolValue;
}

export const setEndOfDay = (date) => {
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    return date
}
