import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";

export function UsersEditDialogHeader() {
  const { actionsLoading, actionsUserLoading } = useSelector(
    (state) => ({
      actionsLoading: state.reports.ak_action_fetching,
      actionsUserLoading: state.customers.ak_action_fetching
    }),
    shallowEqual
  );

  return (
    <>
      {(actionsLoading || actionsUserLoading) && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Редактировать спикеров доклада</Modal.Title>
      </Modal.Header>
    </>
  );
}
