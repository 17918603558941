import React from 'react';
import {NavLink} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";

const PoolsCard = () => {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    //console.log(user)

    return (
        <div
            className="flex-row-fluid"
            style={{margin: '1rem'}}
            id="pools-aside"
        >
            <div className="card card-custom">
                <div className="card-body pt-8">
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <div className="navi-item mb-2">
                            {user &&
                                <NavLink
                                    to="/pools/main-pool"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Опрос при выходе
                                    </span>
                                </NavLink>
                            }

                            {user.shtani === 0 &&
                            <>
                                <NavLink
                                    to="/pools/partner-1"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона IT-Акселератор
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/partner-2"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона SOC
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/partner-3"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона Оптимизация ИТ
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/partner-4"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона Требования регуляторов
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/partner-5"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона Импортозамещение
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/partner-6"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона DevSecOps
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/partner-7"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Анкета
                                    </span>
                                </NavLink>
                            </>
                            }

                            {user.shtani === 1 &&
                            <>
                                <NavLink
                                    to="/pools/client-1"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона IT-Акселератор
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/client-2"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона SOC
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/client-3"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона Оптимизация ИТ
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/client-4"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона Требования регуляторов
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/client-5"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона Импортозамещение
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/client-6"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Зона DevSecOps
                                    </span>
                                </NavLink>

                                <NavLink
                                    to="/pools/client-7"
                                    className="navi-link py-4"
                                    activeClassName="active"
                                >
                                    <span className="navi-text font-size-lg">
                                        Анкета
                                    </span>
                                </NavLink>
                            </>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PoolsCard;
