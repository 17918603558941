import React from 'react';

const PartnerFive = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLScwoIvPKgs9tm4TnNZaK1GyjgKfzgoZbFHzh-qVJfOYKnbrEw/viewform?embedded=true"
                width="100%" height="1475" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default PartnerFive;
