export const zones = {
	'9693986f-a0ad-4f50-9b98-4f740faa942c': 'Общий зал',
	"0d4f60dd-d813-4b50-9e15-a838ca87b217": "Общий чат",
	"985c73ff-5956-4e33-a214-38d2bada0398": "Зона D",
	"7c8723e0-f06f-4e2e-9e60-46f35eaba219": "Стенд UserGate",
	"08051fd8-4737-4473-9243-45b7e04681c7": "Стенд Astra Linux",
	"162575a8-3fe5-4199-b2c8-120950303290": "Стенд Group IB",
	"64d4c92f-3580-4a09-a253-fda883e993a1": "Стенд CommuniGate",
	"c1a580da-f27d-4334-9e82-69b3bab15dd7": "Стенд Ivanti",
	"e8d8a63b-0152-404d-ae81-02d0cf77ce89": "Стенд Cyberprotect",
	"956ccf93-fc1e-4458-8204-136dea4648ad": "Стенд Crosstech",
	"44a1b70e-b97f-4ada-a407-86abe65eae3d": "Стенд Doc Shell",
	"2ce22fc7-8064-4a40-bc52-81c625f4353f": "Стенд Stingray",
	"78fd4f42-ce8e-4987-8846-065b2be684ec": "Зона C",
	"a66e36d1-dc3b-4f8f-b2ab-bef775515dd1": "Стенд Solar Winds",
	"30725892-8ec9-4c92-926c-01c26b9048c6": "Стенд Rostelecom",
	"d1fa6d56-f7cb-4146-bfa1-6f8ff31a4667": "Зона E",
	"70e6f036-f30e-45ea-9102-978c6122e27d": "Зона B",
	"5ca9334a-a319-4ebf-932f-84ecb32cb4af": "Стенд Red Hat",
	"58f2b5c9-69c5-487e-8eae-57d775ac68ee": "Зона A",
	"027d83a4-eb94-4073-9960-c88775a3c591": "Стенд Red Soft",
	"e03cc66d-af1c-46d6-9979-8a6c0dc25660": "Стенд GIZ",
	"3176de8f-3820-415b-9915-d45a88d015b3": "Стенд Kaspersky",
	"4d9ac3ec-c99c-4d9d-ad7f-27a897188849": "Стенд Aqua Security",
	"6cf12649-4b3a-4e90-8061-532e23112c4f": "Зона F",
	"e5bb6c84-10bb-4bb8-9831-8f9cbeac4504": "Стенд ARInteg",
	"5417a229-9554-45d1-9489-61dffa4d2fcc": "Центральный конференц-зал",
	"4932d763-eb2c-427d-a8b5-c191b0308de4": "Конференц-зал A",
	"99942248-5ffd-444a-a6d2-ccabbe8dd3ce": "Конференц-зал B",
	"e106f83c-5389-41e0-a12c-6d2a35a92c41": "Конференц-зал C",
	"a7f4dd8a-52f4-4cf2-a85d-e69b25be0f63": "Конференц-зал D",
	"4bf74f88-c832-45ab-bdac-dec9517d4ea7": "Конференц-зал E",
	"c87e249f-1c36-4ecc-b96e-66c98e9fc69d": "Конференц-зал F"
}