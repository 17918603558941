import { authAPI } from "../api/api";
import { history } from 'react-router-dom'
const LOGIN = 'LOGIN';
const ERROR = 'ERROR';
const SET_USER_DATA = 'SET_USER_DATA';
const SET_FETCHING = 'SET_FETCHING';

const InitialState = {
    user: null,
    isAuth: false,
    isFetching: true,
    GetMeError: false,
}

const authReducer = (state = InitialState, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, isAuth: action.isAuth }
        case ERROR:
            return { ...state, GetMeError: action.error }
        case SET_FETCHING:
            return { ...state, isFetching: action.isFetching }
        case SET_USER_DATA:
            return {
                ...state,
                user: action.payload === null ? null : {
                    ...action.payload,
                    id: action.payload.user_uuid,
                    firstname: action.payload.first_name,
                    lastname: action.payload.last_name,
                    login: action.payload.email,
                    patronymic: action.payload.secon_name,
                    avatar_url: encodeURI(action.payload.photo),
                    session_uuid: action.payload.session_uuid,
                    user_uuid: action.payload.user_uuid
                }
            }
        default:
            return state;
    }
}

export const login = (isAuth) => ({ type: LOGIN, isAuth });
export const setError = (error) => ({ type: ERROR, error });
export const setUserData = (payload) => ({ type: SET_USER_DATA, payload });
const setFetching = (isFetching) => ({ type: SET_FETCHING, isFetching });

export const requestLogin = (values) => async (dispatch) => {
    return await authAPI.login(values);
}

export const getCurrentSession = () => async (dispatch) => {
    try {
        const data = await authAPI.getCurrentSession()
            .then((response) => {
                // console.log('getCurrentSession response', response)
                const { data } = response

                if (data.code === 200) {
                    dispatch(setUserData(data.response))
                    dispatch(login(true))
                }

                dispatch(setFetching(false));
                dispatch(setError(true));

                // TODO : тут поидеи нужно очистить пользователя
            })
            .catch((error) => {
                dispatch(setFetching(false));
                dispatch(setError(true));
                console.log(error.message)
            })
    } catch (error) {
        console.log(error.message)
    }
}

export const checkSession = () => async (dispatch) => {
    dispatch(setFetching(false));
    const token = localStorage.getItem('token');

    if (!token) {
        return;
    }

    const user = localStorage.getItem('user');

    if (!user) {
        return;
    }

    dispatch(setUserData(JSON.parse(user)));
    dispatch(login(true));
}

export const me = (token) => async (dispatch) => {
    dispatch(setError(false));
    dispatch(setFetching(true));
    try {
        let response = await authAPI.me(token);
        if (response) {
            dispatch(setFetching(false));
        }
        if (response.data.resultCode === 200 && response.data.data.enabled) {
            dispatch(setUserData(response.data.data));
            dispatch(login(true));
        }
    } catch (err) {
        dispatch(setFetching(false));
        dispatch(setError(true));
    }
}

export const logout = () => async (dispatch) => {
    dispatch(setUserData(null))
    dispatch(login(false))

    let response = await authAPI.logout();
    console.log('logout', response)
    // localStorage.removeItem('token');
    if (response) {
        dispatch(setFetching(false));
    }
    // if(response.data.resultCode === 200){
    //     dispatch(login(false));
    // }
}

export default authReducer;