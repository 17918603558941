import { ax, getToken } from "./api-setup";

//Constants

export const UPLOAD_VIDEO = 'api/addglobalvideo';
export const GET_VIDEOS = 'api/getlobalvideolist';
export const DELETE_VIDEO = 'api/deletefromglobalvideo';


//Methods

export const videosAPI = {
    getVideos(){
        return ax.get(`${GET_VIDEOS}/${getToken()}`).then(response => response.data);
    },
    uploadVideo(file){
        return ax.post(`${UPLOAD_VIDEO}/${getToken()}`, file).then(response => response.data);
    },
    deleteVideo(key){
        return ax.delete(`${DELETE_VIDEO}/${getToken()}`, {
          data: {
            key: key
          }
        }).then(response => response.data);
    },
}