import React from 'react';

const PartnerThree = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLScN3TcV0gf_Ah5RMCcFRcyIYilAxBmsk0ay7iREzonCXnNlFg/viewform?embedded=true"
                width="100%" height="1376" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default PartnerThree;
