import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { WebglBuild } from "../../app/pages/close";
import { EventsWidget } from "./EventsWidget";
import { Webgl } from "../../app/pages/open";
import { Tutorial } from "../../app/pages/tutorial";
import ChatApp from '../Chat/ChatApp'



export function DashboardPage() {

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Route exact path="/dashboard">
                    <EventsWidget />
                </Route>
                {/*<Route path="/dashboard/build">
                            <Webgl />
                        </Route>
                        <Route path="/dashboard/webglbuild">
                            <WebglBuild />
                        </Route>*/}
                {/* <Route path="/dashboard/online/:eventId">
                            <WebglBuild />
                        </Route> */}
                {/* <Route path="/dashboard/offline/1">
                            <Webgl />
                        </Route> */}
                {/* <Route path="/dashboard/offline/tutorial">
                            <Tutorial />
                        </Route> */}
                <Route path="/dashboard/online">
                    <div style={{
                        width: '110%',
                        marginLeft: '-5%',
                        backgroundColor: 'transparent',
                        display: 'flex',
                        flexDirection: 'row,',
                        justifyContent: 'space-between',
                    }}>
                        <div style={{ width: '392px', marginRight: '15px' }}>
                            <ChatApp />
                        </div>
                        <div style={{ width: '100%', height: '81vh' }}>
                            <Webgl />
                        </div>
                    </div>
                </Route>
            </Switch>
        </Suspense>
    );
}
