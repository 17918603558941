import React from 'react';

const PartnerFour = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSc-IVsrwYKi6YHOfDTwbDWZSMeR99fbyYdMHMdaAtuZ2iTZCA/viewform?embedded=true"
                width="100%" height="1217" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default PartnerFour;
