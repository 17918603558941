/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { me, checkSession, getCurrentSession } from "../redux/auth-reducer";
import { initFilters } from "../redux/users-reducer";

let Routes = () => {
    const { isAuthorized, isFetching } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.isAuth,
            isFetching: auth.isFetching
        }),
        shallowEqual
    );

    const state = useSelector(state => state)

    // init app
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(me());
        dispatch(getCurrentSession())
        dispatch(initFilters());
    }, [dispatch]);

    if (isFetching && !isAuthorized) {
        return <LayoutSplashScreen />
    }

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}

export default Routes;