import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "../components/Dashboard/DashboardPage";
import { Webgl } from "./pages/open"
import { WebglBuild } from "./pages/close"
import { useDispatch } from "react-redux";
import { requestEvents } from "../redux/events-reducer";
import {PoolsPage} from "../components/Pools/PoolsPage";

const Customers = lazy(() =>
  import("../components/Customers/Customers")
);

const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const Events = lazy(() =>
  import("../components/Events/Events")
);

const Reports = lazy(() =>
  import("../components/Reports/Reports")
);

const AdminPage = lazy(() =>
  import("../components/AdminPage")
);

const StatisticsPage = lazy(() =>
  import("../components/StatisticsPage")
);

const Documentation = lazy(() =>
  import("../components/Documentation/Documentation")
);

export default function BasePage() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(requestEvents());
  // }, [dispatch])


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
         <ContentRoute path="/documentation" component={Documentation} />
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        {/* <ContentRoute path="/my-page" component={MyPage} /> */}
        <Route path="/webglbuild" component={WebglBuild} />
        <Route path="/pools" component={PoolsPage} />
        <Route path="/build" component={Webgl} />
        <Route path="/admin" component={AdminPage} />
        {/* <Route path="/statistics" component={StatisticsPage} /> */}
        <Route path="/customers" component={Customers} />
        {/* <Route path="/events" component={Events} /> */}
        <Route path="/reports" component={Reports} />
        <Route path="/user-profile" component={UserProfilePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
