export function startSocketIO(socket, peer, disconnectFromPeer) {
	socket.current.io.on('open', () => {
		console.log('Socket Opened')
	})
	// socket.current.io.on('close', () => {
	// 	console.log('close')
	// 	setTimeout(() => {
	// 		startSocketIO(socket.current, peer,  disconnectFromPeer)
	// 	}, 1000)
	// })
	socket.current.on('connected_result', (arg) => {
	})
	socket.current.on("connect", () => {
		console.log('socket connected => ', socket.current.connected);
	});
	socket.current.on('user_disconnected', (args) => {
		console.log('user_disconnected => ', args)
		if (args.user !== peer.id) {
			disconnectFromPeer(args.user)
		}
	})

}