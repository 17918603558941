import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import { ListItemButton, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { VoiceChatButtonsContext } from '../../../../../../utils/Context';
import { timeFormatHelper } from '../../../../../../utils/TimeFormatter';
import { zones } from '../../../../../../utils/zonesData/zonesData';
import { axoftCompany } from '../../../../../socket/constants';
import { VisitCard } from './VisitCard';

const drawerWidth = 360;

const useStyles = makeStyles({
	toolbarRoot: {
		display: 'flex',
		position: 'relative',
		width: '99.4%',
		backgroundColor: '#fff',
		position: 'fixed !important',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		padding: '0 !important',
		borderTop: ' 1px solid rgba(0, 0, 0, 0.12)',
		boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.1)',
		minHeight: '75px !important',
		marginLeft: '0.2%'
	},
	standTitle: {
		width: '100%',
		marginLeft: '1em',
		fontWeight: 700,
		fontSize: 18,
		textAlign: 'left'
	},
	standName: {
		width: '100%',
		marginLeft: '1em',
		fontWeight: 400,
		fontSize: 14,
		textAlign: 'left'
	},
	badge: {
		position: 'absolute',
		width: '8px',
		height: '8px',
		backgroundColor: '#FF1A6C',
		borderRadius: '50%',
		top: 8,
		left: '20px',
	},
	speaker: {
		backgroundColor: '#fff',
		color: '#262626',
		border: 'none',
		padding: '2px 6px',
		'&:hover': {
			backgroundColor: '#F1F9FA !important',
			border: 'none',
			boxShadow: 'none',
		},
		'&:active': {
			boxShadow: 'none',
			backgroundColor: '#F1F9FA !important',
			border: 'none',
		},
		'&:focus': {
			boxShadow: 'none',
			backgroundColor: '#F1F9FA !important',
			border: 'none',
		},
	},
	myMessage: {
		backgroundColor: '#B1E6EA',
		color: '#272B2E',
		fontSize: '14px',
		padding: '15px',
		display: 'block',
		width: 'fit-content',
		marginLeft: 'auto',
		borderRadius: '8px 8px 0px 8px',
		maxWidth: '65%',
		wordWrap: 'break-word',
	},
	incomeMessage: {
		backgroundColor: '#E9EAF0',
		color: '#272B2E',
		fontSize: '14px',
		padding: '15px',
		display: 'block',
		width: 'fit-content',
		marginRight: 'auto',
		borderRadius: '0px 8px 8px 8px',
		maxWidth: '65%',
		wordWrap: 'break-word',
	}
});

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: '#00AFBC',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#00AFBC',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#b0edf5',
			right: '1px',
		},
		'&:hover fieldset': {
			borderColor: '#00AFBC',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#00AFBC',
		},
	},
});

export const TextTabPanel = () => {
	const classes = useStyles();
	const { socket, messages, setMessages, connectedUsers, roomConnected, currentRoom, setTextPageVisited, personalMessages, setPersonalMessages, vCards, currentMessagesCount, prevMessagesCount, allMessages, setAllMessages } = useContext(VoiceChatButtonsContext);

	const messageBox = useRef();
	const messagesEndRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [messagesToShow, setMessagesToShow] = useState('Общий чат_9693986f-a0ad-4f50-9b98-4f740faa942c');
	const [recieverPeer, setRecieverPeer] = useState('');
	const [message, setMessage] = useState('')
	//если на этой странице, то скроллить
	const [anchorEl, setAnchorEl] = useState(null);
	const [messageIndex, setMessageIndex] = useState(null)

	const openPop = Boolean(anchorEl);
	const id = openPop ? 'simple-popover' : undefined;

	const handleClick = () => {
		setOpen(!open);
	};
	const handleClickPopover = (e, index) => {
		setAnchorEl(e.currentTarget);
		setMessageIndex(index)
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const deleteMessage = () => {
		handleClose();
		setMessages(messages => ({ ...messages, [messagesToShow]: messages[messagesToShow].filter((m, i) => i !== messageIndex ? m : null) }))
	}

	const handleSendMessage = e => {
		e.preventDefault();
		if (message) {
			socket.emit('chatMessage', {
				content: message,
				roomUUID: messagesToShow.split('_')[1]
			});

			setMessage('')
			messageBox.current.value = '';
		}
	}

	const handleSendPrivatMessage = e => {
		e.preventDefault();
		if (message) {
			if ((messagesToShow in personalMessages) === true) {
				setPersonalMessages(personalMessages => ({
					...personalMessages, ...personalMessages[messagesToShow].push({
						me: true,
						currentMinutes: timeFormatHelper(new Date().getMinutes()),
						currentHour: timeFormatHelper(new Date().getHours()),
						message: message,
						name: connectedUsers[0].userName,
						lastName: connectedUsers[0].userLastName,
						read: true
					})
				}))
			} else {
				setPersonalMessages(personalMessages => ({
					...personalMessages, [messagesToShow]: [{
						me: true,
						currentMinutes: timeFormatHelper(new Date().getMinutes()),
						currentHour: timeFormatHelper(new Date().getHours()),
						message: message,
						name: connectedUsers[0].userName,
						lastName: connectedUsers[0].userLastName,
						read: true
					}]
				}))
			}
			socket.emit('sendTo', {
				packet: {
					pmess: {
						message: message,
						name: connectedUsers[0].userName,
						last_name: connectedUsers[0].userLastName,
						reciever_data: messagesToShow
					}
				},
				peerUUID: recieverPeer
			});
			setMessage('')
			messageBox.current.value = '';
		}
	}

	const handleChooseMessagesZone = (name) => {
		setMessagesToShow(name);
		setOpen(!open)
	}

	const handleSaveAs = e => {
		let canvas = document.querySelector(`#drawingCanvas-${recieverPeer}`)
		let dataURL = canvas.toDataURL("image/jpeg");
		let link = document.createElement("a");
		link.href = dataURL;
		link.download = `Визитка ${messagesToShow.split('_')[0]}.jpg`;
		link.click();
	}

	useEffect(() => {
		handleClose()
		if (roomConnected.split('_')[0] !== 'undefined') {
			setMessagesToShow(roomConnected)
		} else {
			connectedUsers[1] && setMessagesToShow(`${connectedUsers[1].userLastName} ${connectedUsers[1].userName}_${connectedUsers[1].peerUUID}`)
		}
	}, [roomConnected, connectedUsers])

	useEffect(() => {
		handleClose()
		messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
	}, [messagesToShow, messagesEndRef.current])

	useEffect(() => {
		handleClose()
		setRecieverPeer(messagesToShow.split('_').splice(1).join('_'));
	}, [messagesToShow]);

	useEffect(() => {
		if ((messagesToShow in currentMessagesCount) && (messagesToShow in prevMessagesCount)) {
			prevMessagesCount[messagesToShow].count = currentMessagesCount[messagesToShow].count
		}
	}, [currentMessagesCount, prevMessagesCount, messagesToShow])

	const refreshBadge = useCallback(() => {
		if (messagesToShow in messages) {
			setMessages(messages => ({ ...messages, [messagesToShow]: messages[messagesToShow].map(message => ({ ...message, read: true })) }))
		}
		if (messagesToShow in personalMessages) {
			setPersonalMessages(personalMessages => ({ ...personalMessages, [messagesToShow]: personalMessages[messagesToShow].map(message => ({ ...message, read: true })) }))
		}

	}, [messages, personalMessages, messagesToShow, setMessages, setPersonalMessages,]);

	useEffect(() => {
		messagesEndRef.current && refreshBadge()
		return () => {
			refreshBadge()
		}
	}, [messagesToShow, messagesEndRef.current])

	return (
		<>
			<Drawer
				sx={{
					position: 'relative',
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						height: '63.5vh',
						left: drawerWidth,
						boxSizing: 'border-box',
					},
					'& .MuiDrawer-paper::-webkit-scrollbar': {
						width: '0px',
						background: 'transparent',
					},
				}}
				variant="permanent"
				anchor="left"
			>
				<Toolbar classes={{ root: classes.toolbarRoot }} sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					}
				}} >
					{!roomConnected &&
						<Typography noWrap component="div" className={classes.standTitle}>
							Загрузка
						</Typography>}
					<List
						sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
						component="nav"
						aria-labelledby="nested-list-subheader"
					>
						<ListItemButton onClick={handleClick}>
							<ListItemText >
								{messagesToShow.split('_')[0] === zones[messagesToShow.split('_')[1]] ?
									<Typography noWrap component="div" className={classes.standTitle}>
										{zones[messagesToShow.split('_')[1]]}
									</Typography>
									:
									<>
										<Typography noWrap component="div" className={classes.standTitle}>
											Личные сообщения
										</Typography>
										<Typography noWrap component="div" className={classes.standName}>
											{messagesToShow.split('_')[0]}
										</Typography>
									</>
								}
							</ListItemText>
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{messagesToShow.split('_')[0] === zones[messagesToShow.split('_')[1]] || roomConnected.split('_')[0] !== 'undefined' ?
								<List component="div" disablePadding>
									<Divider />
									<ListItemText primary=
										{
											messagesToShow.split('_')[0] === zones[messagesToShow.split('_')[1]] ?
												zones[messagesToShow.split('_')[1]]
												:
												`Личные сообщения с ${messagesToShow.split('_')[0]}`
										}
									/>
									{messages && Object.keys(messages).map(sender =>
										<ListItemButton sx={{ pl: 4 }} key={sender} onClick={() => handleChooseMessagesZone(sender)}>
											<ListItemText primary={sender.split('_')[0]} />
											{(sender in currentMessagesCount) && (sender in prevMessagesCount) &&
												<span style={{ display: currentMessagesCount[sender].count !== prevMessagesCount[sender].count ? 'inline' : 'none' }} className={classes.badge}></span>
											}
										</ListItemButton>
									)}
									<Divider />
									{Object.keys(personalMessages).length > 0 && <ListItemText primary="Личные сообщения" />}
									{personalMessages && Object.keys(personalMessages).map(sender =>
										<ListItemButton sx={{ pl: 4 }} key={sender} onClick={() => handleChooseMessagesZone(sender)}>
											<ListItemText primary={sender.split('_')[0]} />
											{(sender in currentMessagesCount) && (sender in prevMessagesCount) &&
												<span style={{ display: currentMessagesCount[sender].count !== prevMessagesCount[sender].count ? 'inline' : 'none' }} className={classes.badge}></span>
											}
										</ListItemButton>
									)}
								</List>
								:
								<List component="div" disablePadding>
									<Divider />
									<ListItemText primary=
										{
											connectedUsers[1] && `Приват с ${connectedUsers[1].userLastName} ${connectedUsers[1].userName}`
										}
									/>
									<Divider />
									{personalMessages &&
										<ListItemButton sx={{ pl: 4 }} onClick={() => handleChooseMessagesZone(messagesToShow)}>
											<ListItemText primary={messagesToShow.split('_')[0]} />
											{(messagesToShow in currentMessagesCount) && (messagesToShow in prevMessagesCount) &&
												<span style={{ display: currentMessagesCount[messagesToShow].count !== prevMessagesCount[messagesToShow].count ? 'inline' : 'none' }} className={classes.badge}></span>
											}
										</ListItemButton>
									}
								</List>
							}
						</Collapse>
					</List>
				</Toolbar>
				<Divider />
				{messages[messagesToShow] ?
					<List
						id='chat-list'
						sx={{ overflowY: 'scroll', marginTop: '75px' }} >
						{messages[messagesToShow] && messages[messagesToShow].map((dataObj, index) => {
							return dataObj.me ?
								(<ListItem
									key={index}
									id={index}
									onClick={(e) => handleClickPopover(e, index)}
								>
									<ListItemText						>
										<Typography className={classes.myMessage}> {dataObj.message}</Typography>
										<Typography
											ref={index === messages[messagesToShow].length - 1 ? messagesEndRef : null}
											align='right' style={{ color: '#C5C7D1', fontSize: '14px' }}>{connectedUsers[0].userName} {connectedUsers[0].userLastName} в {dataObj.currentHour}:{dataObj.currentMinutes}
										</Typography>
									</ListItemText>
									<Popover
										id={id}
										open={openPop}
										anchorEl={anchorEl}
										onClose={handleClose}
										PaperProps={{
											style: {
												border: '1px solid #d5f2f5',
												boxShadow: 'none',
												borderRadius: '8px',
												backgroundColor: '#fff',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'flex-start',
												alignItems: 'flex-start',
											}
										}}
										anchorPosition={{ top: 120, left: 700 }}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'center',
											horizontal: 'right',
										}}
									>
										<MenuItem
											type='button'
											sx={{
												padding: '6px 16px !important',
												textAlign: 'left',
												width: '100%'
											}}
											onClick={deleteMessage}
										>Удалить сообщение</MenuItem>
										{/* <MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И сделать что-то ещё</MenuItem>
								<MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И ещё</MenuItem> */}

									</Popover>
								</ListItem>
								)
								:
								(<ListItem
									key={index}
									id={index}
									onClick={(e) => handleClickPopover(e, index)}
								>
									<ListItemText>
										<Typography className={classes.incomeMessage}> {dataObj.message}</Typography>
										<Typography
											ref={index === messages[messagesToShow].length - 1 ? messagesEndRef : null}
											align='left' style={{ color: '#C5C7D1', fontSize: '14px' }}>
											{dataObj.name} {dataObj.lastName} в {dataObj.currentHour}:{dataObj.currentMinutes}
										</Typography>
									</ListItemText>
									<Popover
										id={id}
										open={openPop}
										anchorEl={anchorEl}
										onClose={handleClose}
										PaperProps={{
											style: {
												border: '1px solid #d5f2f5',
												boxShadow: 'none',
												borderRadius: '8px',
												backgroundColor: '#fff',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'flex-start',
												alignItems: 'flex-start',
											}
										}}
										anchorPosition={{ top: 120, left: 700 }}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'center',
											horizontal: 'right',
										}}
									>
										<MenuItem
											type='button'
											sx={{
												padding: '6px 16px !important',
												textAlign: 'left',
												width: '100%'
											}}
											onClick={deleteMessage}
										>Удалить сообщение</MenuItem>
										{/* <MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И сделать что-то ещё</MenuItem>
								<MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И ещё</MenuItem> */}

									</Popover>
								</ListItem>)
						}
						)}
					</List>
					:
					<List
						id='chat-list'
						sx={{ overflowY: 'scroll', marginTop: '75px' }} >
						{/* {vCards && vCards.map(card => card.peerUUID === recieverPeer ?
							(
								<VisitCard
									key={card.peerUUID}
									canvasOnClick={handleSaveAs}
									id={card.peerUUID}
									userPhone={card.userPhone}
									userEmail={card.userEmail}
									userCompany={card.userCompany}
									userPosition={card.userPosition}
									userLastName={card.userLastName}
									userName={card.userName}
									userSecondName={card.userSecondName}
								/>
							)
							:
							null
						)} */}
						{personalMessages[messagesToShow] && personalMessages[messagesToShow].map((dataObj, index) => {
							return dataObj.me ?
								(<ListItem
									key={index}
									id={index}
									onClick={(e) => handleClickPopover(e, index)}>
									<ListItemText>
										<Typography className={classes.myMessage}> {dataObj.message}</Typography>
										<Typography
											ref={index === personalMessages[messagesToShow].length - 1 ? messagesEndRef : null}
											align='right' style={{ color: '#C5C7D1', fontSize: '14px' }}>{connectedUsers[0].userName} {connectedUsers[0].userLastName} в {dataObj.currentHour}:{dataObj.currentMinutes}
										</Typography>
									</ListItemText>
									<Popover
										id={id}
										open={openPop}
										anchorEl={anchorEl}
										onClose={handleClose}
										PaperProps={{
											style: {
												border: '1px solid #d5f2f5',
												boxShadow: 'none',
												borderRadius: '8px',
												backgroundColor: '#fff',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'flex-start',
												alignItems: 'flex-start',
											}
										}}
										anchorPosition={{ top: 120, left: 700 }}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										transformOrigin={{
											vertical: 'center',
											horizontal: 'right',
										}}
									>
										<MenuItem
											type='button'
											sx={{
												padding: '6px 16px !important',
												textAlign: 'left',
												width: '100%'
											}}
											onClick={deleteMessage}
										>Удалить сообщение</MenuItem>
										{/* <MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И сделать что-то ещё</MenuItem>
								<MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И ещё</MenuItem> */}

									</Popover>
								</ListItem>
								)
								:
								(<ListItem
									key={index}
									style={{ flexWrap: 'wrap' }}
								>
									{dataObj.isCard ?
										<>
											<Typography component={'span'}>
												<VisitCard
													canvasOnClick={handleSaveAs}
													id={dataObj.peerUUID}
													userPhone={dataObj.userPhone}
													userEmail={dataObj.userEmail}
													userCompany={dataObj.userCompany}
													userPosition={dataObj.userPosition}
													userLastName={dataObj.userLastName}
													userName={dataObj.userName}
													userSecondName={dataObj.userSecondName}
												/>
											</Typography>
											<Typography
												align='left' style={{ color: '#C5C7D1', fontSize: '14px' }}>
												{/* {dataObj.name} {dataObj.lastName} */}
												{messagesToShow.split('_')[0]} в {dataObj.currentHour}:{dataObj.currentMinutes}
											</Typography>
										</>
										:
										<ListItemText
											id={index}
											onClick={(e) => handleClickPopover(e, index)}
										>
											<Typography className={classes.incomeMessage}> {dataObj.message}</Typography>
											<Typography
												ref={index === personalMessages[messagesToShow].length - 1 ? messagesEndRef : null}
												align='left' style={{ color: '#C5C7D1', fontSize: '14px' }}>
												{/* {dataObj.name} {dataObj.lastName} */}
												{messagesToShow.split('_')[0]} в {dataObj.currentHour}:{dataObj.currentMinutes}
											</Typography>
											<Popover
												id={id}
												open={openPop}
												anchorEl={anchorEl}
												onClose={handleClose}
												PaperProps={{
													style: {
														border: '1px solid #d5f2f5',
														boxShadow: 'none',
														borderRadius: '8px',
														backgroundColor: '#fff',
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'flex-start',
														alignItems: 'flex-start',
													}
												}}
												anchorPosition={{ top: 120, left: 700 }}
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}}
												transformOrigin={{
													vertical: 'center',
													horizontal: 'right',
												}}
											>
												<MenuItem
													type='button'
													sx={{
														padding: '6px 16px !important',
														textAlign: 'left',
														width: '100%'
													}}
													onClick={deleteMessage}
												>Удалить сообщение</MenuItem>
												{/* <MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И сделать что-то ещё</MenuItem>
								<MenuItem sx={{
									padding: '6px 16px !important',
									textAlign: 'left',
									width: '100%'
								}}>И ещё</MenuItem> */}

											</Popover>
										</ListItemText>
									}
								</ListItem>
								)
						}
						)}
					</List>
				}

			</Drawer>
			<Box
				component="form"
				onSubmit={messagesToShow.split('_')[0] === zones[messagesToShow.split('_')[1]] ? handleSendMessage : handleSendPrivatMessage}
				sx={{
					'& .MuiTextField-root': { m: 1, width: '25ch' },
				}}
				noValidate
				autoComplete="off"
				sx={{
					position: 'fixed',
					bottom: '2%',
					width: drawerWidth - 1,
				}}
			>
				<CssTextField
					label="Введите сообщение..."
					id="custom-css-outlined-input"
					onChange={(e) => setMessage(e.target.value)}
					inputProps={{
						style: { paddingRight: '10%' },
						ref: input => messageBox.current = input
					}}
					sx={{
						width: '99.9%'
					}}
				/>
				<Button
					type='submit'
					sx={{
						position: 'absolute',
						bottom: '20%',
						right: 0,
						color: '#00AFBC'
					}}
				>
					<SendIcon />
				</Button>
			</Box>
		</>
	)
}












{/* <ListItemButton onClick={handleClick}>
							<ListItemText primary={roomConnected === axoftCompany || roomConnected === 'lobby' ?
								<Typography noWrap component="div" className={classes.standTitle}>
									{messagesToShow !== 'zone' ?
										<>
											Личные сообщения
											<Typography noWrap component="div" className={classes.standName}>
												{messagesToShow}
											</Typography></>
										:
										'Общий чат'}
								</Typography>
								:
								roomConnected in zones
									?
									<Typography noWrap component="div" className={classes.standTitle}>
										{zones[currentRoom]}
									</Typography>
									:
									<Typography noWrap component="div" className={classes.standTitle}>
										Приватный чат с {connectedUsers.length > 1 && connectedUsers[1].userLastName} {connectedUsers.length > 1 && connectedUsers[1].userName}
										{currentRoom === 'lobby' ?
											<ListItemButton
												className={classes.speaker}
												onClick={backToMainLobby}>
												<ListItemText
													primary={'Вернуться в общий зал'}
												// secondary={index % 2 === 0 ? 'Secondary text' : null}
												/>
											</ListItemButton>
											:
											<ListItemButton
												className={classes.speaker}
												onClick={backToPreviousRoom}>
												<ListItemText
													primary={`Вернуться в ${ zones[currentRoom]} `}
												// secondary={index % 2 === 0 ? 'Secondary text' : null}
												/>
											</ListItemButton>
										}
									</Typography>
							}
							/> */}