import React from 'react';

const ClientSix = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSfqts1DfX0o2z4krg36c5vS49E2soVItwZsUGfiGK2UvOg-DQ/viewform?embedded=true"
                width="100%" height="1582" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default ClientSix;
