/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {EventsList} from "./EventsList";


export function EventsWidget() {
    return (
        <>
            <EventsList />
        </>
    );
}
