import React, { useEffect } from 'react';
import ChatContainer from './ChatContainer';


const ChatApp = () => {
  return (
    <div style={{ marginBottom: '25px' }}>
      <ChatContainer />
    </div>
  )
}

export default ChatApp

