import { standsAPI } from "../api/stands-api";

const FETCHING_START = 'FETCHING_START';
const FETCHING_END = 'FETCHING_END';
const GET_ALL_STANDS = 'GET_ALL_STANDS';
const SET_SELECTED_STAND = 'SET_SELECTED_STAND';
const GET_ALL_USERS = 'GET_ALL_USERS';
const SET_SELECTED_SPEAKERS_ROW = 'SET_SELECTED_SPEAKERS_ROW';
const GET_SELECTED_STAND_FULL_INFO = 'GET_SELECTED_STAND_FULL_INFO';

const initialState = {
  stands: [],
  allUsers: [],
  isFectching: false,
  selectedSpeakersRow: null,
  selectedStand: null,
  selectedStandFullInfo: null,
  cdnLink: '',
  names: null
}

const standsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_START: {
      return {
        ...state,
        isFectching: true
      }
    }
    case FETCHING_END: {
      return {
        ...state,
        isFectching: false
      }
    }
    case GET_ALL_STANDS:
      return {
        ...state,
        stands: action.payload.sort((a, b) => a.local_id > b.local_id ? 1 : -1)
      }
    case SET_SELECTED_SPEAKERS_ROW:
      return {
        ...state,
        selectedSpeakersRow: action.payload
      }
    case SET_SELECTED_STAND:
      return {
        ...state,
        selectedStand: state.stands.filter(stand => stand.local_id === action.id)
      }
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload
      }
    case GET_SELECTED_STAND_FULL_INFO:
      return {
        ...state,
        selectedStandFullInfo: action.payload,
        cdnLink: action.payload.content_url,
        names: action.payload.content_keys
      }
    default:
      return state;
  }
}


//AC
const setAllStands = (payload) => ({ type: GET_ALL_STANDS, payload })

export const setFetchingStart = () => ({ type: FETCHING_START });

export const setFetchingEnd = () => ({ type: FETCHING_END });

export const getSelectedStand = id => ({ type: SET_SELECTED_STAND, id });

export const getSelectedSpeakersRow = payload => ({ type: SET_SELECTED_SPEAKERS_ROW, payload });

export const setAllUsers = payload => ({ type: GET_ALL_USERS, payload })

export const setSelectedStandFullInfo = payload => ({ type: GET_SELECTED_STAND_FULL_INFO, payload })

//thunk
export const getAllStands = (event_uuid = '123') => async (dispatch) => {
  const response = await standsAPI.getStands(event_uuid);
  if (response.response) {
    dispatch(setAllStands(response.response));
  }
}

export const createStand = (data, event_uuid = '123') => async (dispatch) => {
  try {
    dispatch(setFetchingStart())
    const postData = {
      event_uuid,
      ...data
    }

    const result = await standsAPI.createStand(postData);

    if (result.data.code === 200) {
      dispatch(getAllStands());
      return true;
    }

    return false;
  } catch (e) {
  } finally {
    dispatch(setFetchingEnd())
  }
}

export const updateExistingStand = (data, event_uuid = '123') => async (dispatch) => {
  try {
    dispatch(setFetchingStart())
    const postData = {
      event_uuid,
      ...data
    }

    const result = await standsAPI.updateStand(postData);

    if (result.data.code === 200) {
      dispatch(getAllStands());
      return true;
    }

    return false;
  } catch (e) {
  } finally {
    dispatch(setFetchingEnd())
  }
}

export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch(setFetchingStart());
    const result = await standsAPI.getAllUsers();

    if (result.data.code === 200) {
      dispatch(setAllUsers(result.data.response));
      return true;
    }

    return false;
  } catch (e) {
  } finally {
    dispatch(setFetchingEnd())
  }
}

export const updateSpeakersForStand = (data, event_uuid = '123') => async (dispatch) => {
  try {
    dispatch(setFetchingStart())
    const postData = {
      event_uuid,
      ...data
    }
    const result = await standsAPI.updateSpeakers(postData);

    if (result.data.code === 200) {
      dispatch(getAllStands());
      return true;
    }

    return false;
  } catch (e) {
  } finally {
    dispatch(setFetchingEnd())
  }
}

export const deleteStandFromList = (data, event_uuid = '123') => async (dispatch) => {
  try {
    dispatch(setFetchingStart())
    const postData = {
      event_uuid,
      ...data
    }
    console.log(postData);
    const result = await standsAPI.deleteStand(postData);

    if (result.data.code === 200) {
      dispatch(getAllStands());
      return true;
    }

    return false;
  } catch (e) {
  } finally {
    dispatch(setFetchingEnd())
  }
}

export const getStandFullInfo = (data, event_uuid = '123') => async (dispatch) => {
  try {
    dispatch(setFetchingStart())
    const postData = {
      event_uuid,
      ...data
    }

    const result = await standsAPI.getFullStandInfo(postData);

    if (result.data.code === 200) {
      dispatch(setSelectedStandFullInfo(result.data.response));
      return true;
    }

    return false;
  } catch (e) {
  } finally {
    dispatch(setFetchingEnd())
  }
}

export const updateStandContent = (data, event_uuid = '123') => async (dispatch) => {
  try {
    dispatch(setFetchingStart())
    const postData = {
      event_uuid,
      ...data
    }
    console.log(data);
    const result = await standsAPI.updateStandContent(postData);

    if (result.data.code === 200) {
      dispatch(getStandFullInfo(postData.local_id));
      return true;
    }

    return false;
  } catch (e) {
  } finally {
    dispatch(setFetchingEnd())
  }
}

export default standsReducer;