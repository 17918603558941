import React from 'react';

const PartnerOne = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSe_zDWc0WlD7IMpcQ-2yvF7qSfQQW2WViJm0cFC1L75tDZNLQ/viewform?embedded=true"
                width="100%" height="1441" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default PartnerOne;
