import React from "react";
import s from './MetronicSplashScreen.module.css';

export function LayoutSplashScreen() {
  return (
    <div className={s.splash}>
      <img
        src={`${process.env.PUBLIC_URL}/media/logos/axoft.png`}
        alt="Axoft logo"
      />
      <svg className={s.spinner} viewBox="0 0 50 50">
        <circle className={s.path}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </div>
  )
}