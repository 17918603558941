import { ax, getToken } from "./api-setup";

// Constants

// export const GET_ALL_USERS = 'api/getallusers';
export const ADD_USER = 'api/adduser';
export const ADD_GUEST = 'api/addguest';
export const ACTIVATION_GUEST = 'api/activateguest';
export const DELETE_USER = 'api/deluser';
export const UPDATE_USER = 'api/updateuserinfo';
export const UPDATE_USER_PASS = 'api/updateuserpass';
export const UPDATE_SELF_PASS = 'api/updateselfpass';
export const UPDATE_USER_PHOTO = 'api/updateuserphoto';
export const DELETE_USER_PHOTO = 'api/removeuserphoto';

export const PROFILE_UPDATE = 'api/account/profile/profile-update'
export const PASSWORD_UPDATE = 'api/account/profile/password-update'
export const GET_ALL_USERS = '/api/account/admin/get-all-system-users'
export const CREATE_USER = '/api/account/admin/force-add-user'
export const CHANGE_USER_PASSWORD = '/api/account/admin/change-user-password'
export const CHANGE_USER_ROLE = '/api/account/admin/change-system-role'
export const UNBLOCK_USER = '/api/account/admin/unblock-system-user'
export const BLOCK_USER = '/api/account/admin/block-system-user'
export const REMOVE_USER = '/api/account/admin/delete-user'
export const CHANGE_USER = '/api/account/admin/user-profile-update'
export const GET_USER = '/api/account/get';

// Methods

export const usersAPI = {
  updateUser(userData) {
    return ax.post(
      PROFILE_UPDATE,
      { ...userData },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  passwordUpdate(old_password, new_password) {
    return ax.post(
      PASSWORD_UPDATE,
      {
        old_password,
        new_password,
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  getUsers() {
    return ax.get(
      GET_ALL_USERS,
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },

  createUser(data) {
    return ax.post(
      CREATE_USER,
      {
        ...data,
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  changeUser(userData) {
    return ax.post(
      CHANGE_USER,
      {
        ...userData,
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  changeUserPassword(uuid, new_password) {
    return ax.post(
      CHANGE_USER_PASSWORD,
      {
        uuid,
        new_password
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  changeUserRole(uuid, new_role) {
    return ax.post(
      CHANGE_USER_ROLE,
      {
        uuid,
        new_role
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  unblockUser(uuid) {
    return ax.post(
      UNBLOCK_USER,
      {
        uuid,
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  blockUser(uuid) {
    return ax.post(
      BLOCK_USER,
      {
        uuid,
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  removeUser(uuid) {
    console.log('post removeUser')
    return ax.post(
      REMOVE_USER,
      {
        uuid,
      },
      {
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },
  getUser(uuid) {
    return ax.get(
      GET_USER,
      {
        params: {
          uuid
        },
        headers: {
          'x-viexpo-session-token': localStorage.getItem('token'),
        },
      },
    )
  },

  // updateUser(customer){
  //     return ax.put(`${UPDATE_USER}/${getToken()}`,
  //         {
  //             obj_id: customer.id,
  //             login: customer.login,
  //             roleID: customer.roleID,
  //             last_name: customer.last_name,
  //             first_name: customer.first_name,
  //             patronymic: customer.patronymic,
  //             phone: customer.phone,
  //             company: customer.company,
  //             job: customer.job,
  //             agreement: customer.agreement,
  //             avatar_url: customer.avatar_url,
  //             enabled: customer.enabled
  //             //character_avatar: customer.character_avatar
  //         }
  //     );
  // },
  // getUsers() {
  //     return ax.get(`${GET_ALL_USERS}/${getToken()}/getshortinfo`)
  //         .then(response => response.data);
  // },
  getFullUsers() {
    return ax.get(`${GET_ALL_USERS}/${getToken()}/getfullinfo`)
      .then(response => response.data);
  },
  addUser(customer) {
    return ax.post(`${ADD_USER}/${getToken()}`,
      {
        login: customer.login,
        password: customer.password,
        roleID: customer.roleID,
        last_name: customer.last_name,
        first_name: customer.first_name,
        patronymic: customer.patronymic,
        phone: customer.phone,
        company: customer.company,
        job: customer.job,
        agreement: customer.agreement,
        enabled: customer.enabled
        //character_avatar: customer.character_avatar
      }
    );
  },
  addGuest(customer) {
    return ax.post(`${ADD_GUEST}`,
      {
        //site_link: "http://localhost:3000/auth/activation/",
        site_link: "https://aquarius.viexpo.ru/auth/activation/",
        login: customer.login,
        password: customer.password,
        last_name: customer.last_name,
        first_name: customer.first_name,
        patronymic: customer.patronymic,
        phone: "",
        company: customer.company,
        job: customer.job,
        agreement: customer.agreement
      }
    ).then(response => response.data);
  },

  activationGuest(key) {
    return ax.put(`${ACTIVATION_GUEST}/${key}`);
  },

  deleteUser(id) {
    return ax.delete(`${DELETE_USER}/${getToken()}`, {
      data: {
        obj_id: id
      }
    }).then(response => response.data);
  },
  updatePassword(password, id) {
    return ax.put(`${UPDATE_USER_PASS}/${getToken()}`, {
      obj_id: id,
      password: password
    }).then(response => response.data);
  },
  // updateSelfPassword(oldpassword, password){
  //     return ax.put(`${UPDATE_SELF_PASS}/${getToken()}`,{
  //         oldpassword: oldpassword,
  //         newpassword: password
  //     }).then(response => response.data);
  // },
  updateUserPhoto(formData) {
    return ax.post(`${UPDATE_USER_PHOTO}/${getToken()}`, formData)
      .then(response => response.data);
  },
  removePhoto(id) {
    return ax.put(`${DELETE_USER_PHOTO}/${getToken()}`, {
      user_id: id
    })
      .then(response => response.data);
  },
}
