import React from 'react'

export function getVideoAudioStream(constraints) {
	const myNavigator = navigator.mediaDevices.getUserMedia;
	return myNavigator(constraints);
}

export const Selects = ({ videoDevice, audioDevice, setAudioSelectValue, setVideoSelectValue, audioSelectValue, videoSelectValue, audioOutputDevice, audioOutputSelectValue, setAudioOutputSelectValue, }) => {
	const audioOnChange = (e) => {
		setAudioSelectValue(e.target.value)
	}

	const videoOnChange = (e) => {
		setVideoSelectValue(e.target.value)
	}

	const audioOutPutOnChange = (e) => {
		setAudioOutputSelectValue(e.target.value)
	}
	return (
		<div className={'inputs'}>
			<div className={'labelAndSelect'}>
				<label htmlFor="audioSource">Аудио устройство ввода:  </label>
				<select id="audioSource" onChange={audioOnChange}
					value={audioSelectValue ? audioSelectValue : ''}>
					{audioDevice && audioDevice.map((dev, idx) => {
						return <option
							key={idx}
							value={dev.value}						>
							{dev.text}
						</option>
					})}
				</select>
			</div>
			<div className={'labelAndSelect'}>
				<label htmlFor="videoSource"> Видео устройство ввода:  </label>
				<select id="videoSource" onChange={videoOnChange}
					value={videoSelectValue ? videoSelectValue : ''}>
					{videoDevice && videoDevice.map((dev, idx) => {
						return <option
							key={idx}
							value={dev.value}						>
							{dev.text}
						</option>
					})}
				</select>
			</div>
			<div className={'labelAndSelect'}>
				<label htmlFor="audioOutput"> Аудио устройство вывода:  </label>
				<select id="audioOutput" onChange={audioOutPutOnChange}
					value={audioOutputSelectValue ? audioOutputSelectValue : ''}>
					{audioOutputDevice && audioOutputDevice.map((dev, idx) => {
						return <option
							key={idx}
							value={dev.value}						>
							{dev.text}
						</option>
					})}
				</select>
			</div>
		</div>
	)
}