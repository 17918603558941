import { ax, getToken } from "./api-setup";

//Constants

export const GET_USER_STAT = 'api/getusersstat';
export const GET_DEVICE_STAT = 'api/getudevicestatforevent';
export const GET_USERS_STAT = 'api/getusersstatforevent';
export const GET_REPORTS_STAT = 'api/getureportstatforevent';

//Methods

export const statsAPI = {
    getStats(){
        return ax.get(`${GET_USER_STAT}/21m0dsfj3120isdc0ij130jdc01c`).then(response => response.data);
    },
    getDeviceStats(event_id, begin_time, end_time){
        return ax.post(`${GET_DEVICE_STAT}/${getToken()}`, {
            event_id: event_id,
            begin_time : begin_time,
            end_time : end_time
        }).then(response => response.data);
    },
    getUsersStats(event_id, begin_time, end_time){
        return ax.post(`${GET_USERS_STAT}/${getToken()}`, {
            event_id: event_id,
            begin_time : begin_time,
            end_time : end_time
        }).then(response => response.data);
    },
    getReportsStats(event_id, begin_time, end_time){
        return ax.post(`${GET_REPORTS_STAT}/${getToken()}`, {
            event_id: event_id,
            begin_time : begin_time,
            end_time : end_time
        }).then(response => response.data);
    },
}