import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UsersEditDialogHeader } from "./UsersEditDialogHeader";
import { UsersEditTable } from "./UsersEditTable";
import { getReportById, ak_setSelectedReport } from "../../../redux/reports-reducer";
import { requestUsers } from "../../../redux/users-reducer";

export function UsersEditDialog({ show, onHide }) {
  const dispatch = useDispatch();

  const report = useSelector(state => state.reports.ak_selected_report);

  //console.log('UsersEditDialog report', report)

  useEffect(() => {
    async function fetchMyAPI() {
      if (report) {
        await dispatch(requestUsers());
        await dispatch(getReportById(report.report_uuid));
      }
    }

    fetchMyAPI()

    return () => {
      dispatch(ak_setSelectedReport(null))
    }
  }, []);

  return (
    <>
      <UsersEditDialogHeader />
      {report && <UsersEditTable onHide={onHide} />}
    </>
  )
}
