import React from "react";
import UsersList from "./UsersList";
import { shallowEqual, useSelector } from "react-redux";

export function UsersEditTable({ id, onHide }) {
    const { users, report, ak_action_fetching } = useSelector(
        (state) => ({
            users: state.customers.ak_users,
            report: state.reports.ak_selected_report,
            ak_action_fetching: state.reports.ak_action_fetching,
        }),
        shallowEqual
    );

    if (!users.length || ak_action_fetching) {
        return <p>Список загружается...</p>
    }

    let ids = [];

    //console.log('report', report)

    report && report.speakers && Array.isArray(report.speakers) && report.speakers.forEach(speaker => {
        ids.push(speaker.uuid)
    })

    console.log('ids', ids)

    return (
        <UsersList ids={[...ids]} onHide={onHide} />
    );
}
