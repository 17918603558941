import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import PoolMain from "./PoolsList/PoolMain";
import PoolsCard from "./PoolsCard";
import PartnerOne from "./PoolsList/PartnerOne";
import PartnerTwo from "./PoolsList/PartnerTwo";
import PartnerThree from "./PoolsList/PartnerThree";
import PartnerFour from "./PoolsList/PartnerFour";
import PartnerFive from "./PoolsList/PartnerFive";
import PartnerSix from "./PoolsList/PartnerSix";
import PartnerCard from "./PoolsList/PartnerCard";
import {shallowEqual, useSelector} from "react-redux";
import ClientOne from "./PoolsList/ClientOne";
import ClientTwo from "./PoolsList/ClientTwo";
import ClientThree from "./PoolsList/ClientThree";
import ClientFour from "./PoolsList/ClientFour";
import ClientFive from "./PoolsList/ClientFive";
import ClientSix from "./PoolsList/ClientSix";
import ClientCard from "./PoolsList/ClientCard";

export function PoolsPage() {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    return (
        <div className="d-flex flex-column-fluid" style={{flexWrap: 'wrap', margin: '-1rem'}}>
            <PoolsCard />
            <div className="flex-row-fluid" style={{flex: '3 3 50%', margin: '1rem'}}>
                <Switch>
                    <Redirect
                        from="/pools"
                        exact={true}
                        to="/pools/main-pool"
                    />

                    <Route
                        path="/pools/main-pool"
                        component={PoolMain}
                    />

                    {user.shtani === 0 &&
                        <>
                            <Route
                                path="/pools/partner-1"
                                component={PartnerOne}
                            />

                            <Route
                                path="/pools/partner-2"
                                component={PartnerTwo}
                            />

                            <Route
                                path="/pools/partner-3"
                                component={PartnerThree}
                            />

                            <Route
                                path="/pools/partner-4"
                                component={PartnerFour}
                            />

                            <Route
                                path="/pools/partner-5"
                                component={PartnerFive}
                            />

                            <Route
                                path="/pools/partner-6"
                                component={PartnerSix}
                            />

                            <Route
                                path="/pools/partner-7"
                                component={PartnerCard}
                            />
                        </>
                    }

                    {user.shtani === 1 &&
                    <>
                        <Route
                            path="/pools/client-1"
                            component={ClientOne}
                        />

                        <Route
                            path="/pools/client-2"
                            component={ClientTwo}
                        />

                        <Route
                            path="/pools/client-3"
                            component={ClientThree}
                        />

                        <Route
                            path="/pools/client-4"
                            component={ClientFour}
                        />

                        <Route
                            path="/pools/client-5"
                            component={ClientFive}
                        />

                        <Route
                            path="/pools/client-6"
                            component={ClientSix}
                        />

                        <Route
                            path="/pools/client-7"
                            component={ClientCard}
                        />
                    </>
                    }

                </Switch>
            </div>
        </div>
    );
}
