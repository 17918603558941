import React, {useState} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../index";
import {usersAPI} from "../../../../api/users-api";
import {Redirect} from "react-router-dom"

const ActivationSchema = Yup.object().shape({
    key: Yup.string()
        .matches(/^[0-9]*$/, 'Неверный формат ввода. Допустимы только цифры.')
        .required("Код активации обязателен"),
})

function Activation({regKey}) {
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues: {key: regKey || ''},
        validationSchema: ActivationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            usersAPI.activationGuest(values.key).then(
                response => {
                    disableLoading();
                    setSubmitting(false);
                    if (response.data.resultCode === 200) {
                        disableLoading();
                        setSubmitting(false);
                        setRedirect(true);

                    }
                    if (response.data.resultCode === 400) {
                        setStatus(
                            'Введен неверный код подтверждения'
                        );
                    }
                    if (response.data.resultCode === 500) {
                        setStatus(
                            'Ошибка сервера. Пожалуйста обратитесь к админастратору.'
                        );
                    }
                }
            )
                .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    //setError();
                });
        },
    });

    return (
        <>
            {redirect ? <Redirect to='/auth/login'/> :
                <div>
                    <div className="text-center mb-10">
                        <h3 className="font-size-h1">
                            Активация учетной записи
                        </h3>
                        <p className="text-muted font-weight-bold">
                            Введите код подтверждения отправленный на ваш Email
                        </p>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        {formik.status &&
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                        }

                        <div className="fv-plugins-icon-container">
                            <input
                                placeholder="Код активации"
                                type="text"
                                className={`form-control form-control-solid ${getInputClasses(
                                    "key"
                                )}`}
                                name="key"
                                {...formik.getFieldProps("key")}
                            />
                            {formik.touched.key && formik.errors.key ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.key}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-end pt-5">
                            <button
                                type="submit"
                                disabled={
                                    formik.isSubmitting
                                }
                                className="btn btn-primary font-weight-bold px-9 py-4"
                            >
                                <span>Отправить</span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default injectIntl(connect(null, auth.actions)(Activation));

