import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useSelector, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { requestLogin, getCurrentSession } from "../../../../redux/auth-reducer";
import CustomPasswordInput from "../../../../components/controls/usePasswordToggle/usePasswordToggle"
import {isMobile} from 'react-device-detect';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

function Login(props) {
    const dispatch = useDispatch()
    const { intl } = props;
    const [loading, setLoading] = useState(false);

    const error = useSelector(state => state.auth.GetMeError);

    console.log('error', error);

    function setError(error) {
        console.log('setError', error)
        if (error === 'auth') {
            formik.setStatus(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.AUTH_ERROR",
                })
            );
        } else {
            formik.setStatus(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.NETWORK_ERROR",
                })
            );
        }
    }

    // useEffect(() => {
    // if (error) {
    //     setError(error);
    // }
    // eslint-disable-next-line
    // }, [error]);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Неправильный формат почты")
            .min(3, "Минимум 3 символа")
            .max(50, "Максимум 50 символов")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
        password: Yup.string()
            .min(3, "Минимум 3 символа")
            .max(50, "Максимум 50 символов")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD",
                })
            ),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const initialValues = {
        email: "",
        password: "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            console.log('props', props)
            enableLoading();
            props.requestLogin(values).then(
                response => {
                    console.log('response', response);
                    disableLoading();
                    setSubmitting(false);

                    if (response.data.code === 200) {
                        console.log('if', response.data.code)
                        disableLoading();
                        setSubmitting(false);

                        localStorage.setItem('token', response.data.session_uuid);

                        dispatch(getCurrentSession())
                    }

                    if (response.data.code === 400) {
                        setStatus(
                            intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN",
                            })
                        );
                    }
                    if (response.data.code === 500) {
                        setStatus(
                            intl.formatMessage({
                                id: "AUTH.VALIDATION.SERVER_ERROR",
                            })
                        );
                    }
                }
            )
                .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setError();
                });
        },
    });

    return (
        <>
            {!isMobile ?
                <div className="login-form login-signin" id="kt_login_signin_form">
                    {/* begin::Head */}
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            <FormattedMessage id="AUTH.LOGIN.TITLE" />
                        </h3>
                        <p className="text-muted font-weight-bold">
                            Введите свой логин и пароль
                        </p>
                    </div>
                    {/* end::Head */}

                    {/*begin::Form*/}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        {formik.status &&
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                        }

                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Email"
                                type="email"
                                className={`form-control form-control-solid ${getInputClasses(
                                    "email"
                                )}`}
                                name="email"
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.email}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="input-group mb-4">
                            <CustomPasswordInput
                                placeholder="Пароль"
                                InputClass="password"
                                getInputClasses={`form-control form-control-solid ${getInputClasses("password")}`}
                                formikProps={formik.getFieldProps("password")}
                                touched={formik.touched.password}
                                errors={formik.errors.password}
                            />
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                            <Link
                                to="/auth/forgot-password"
                                className="text-dark-50 text-hover-primary my-3 mr-2"
                                id="kt_login_forgot"
                            >
                                {/*<FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />*/}
                            </Link>
                            <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={formik.isSubmitting}
                                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                            >
                                <span>Войти</span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>
                :
                <div className="login-form login-signin" id="kt_login_signin_form">
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">
                            Доступ ограничен
                        </h3>
                        <p className="text-muted font-weight-bold">
                            Чтобы получить доступ к платформе, пожалуйста, зайдите с персонального компьютера или ноутбука.
                            Поддержка мобильных устройств не осуществляется.
                        </p>
                    </div>
                </div>
            }
        </>
    );
}

export default injectIntl(connect(null, { requestLogin })(Login));
