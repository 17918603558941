import { blackListsApi } from "../api/blackLists-api";

const SET_ALL_VALUES = 'SET_ALL_VALUES';
const SET_LOADING = 'SET_LOADING';

const initialState = {
    listTypes: [], // [{id: number, name: string},...]
    listValues: [], // [{type: string, value: string},...]
    isLoading: false
}

export const BlacklistReducer = (state = initialState, action) => {
    switch (action.type){
        case SET_ALL_VALUES:
        {
            //console.log(`set values: ${action.payload}`);
            return {...state, listValues: [...action.payload]};
        }
        case SET_LOADING:
        {
            //console.log(`loading ${action.payload?'start':'end'}`);
            return {...state, isLoading: action.payload}; //isLoading = true | false
        }
        default :
        {
            return {...state}
        }
    }
}

const setAllValues = (payload) => ({type: SET_ALL_VALUES, payload}) //payload ["string", "string"] 
const setLoading = (payload) => ({type: SET_LOADING, payload}) //payload = true | false

export const getLists = () => async (dispatch) => {
    dispatch(setLoading(true));
    let lists_types = [] //array of all type names
    let list_values = [] //array of all values (of all types)

    const response = await blackListsApi.getAllTypes(); //get [{id: num, name: str},...]

    if(response.data){
        for(const type of response.data){
            lists_types.push(type.id) // add types ids to temp array
        }

        for(const type_id of lists_types){
            await blackListsApi.getList(type_id).then(r=>{
                r.data.forEach(element => {
                    list_values.push(element.value);
                })
            });
        }    
    }
    dispatch(setAllValues(list_values.map((item, index) => ({id: index, value: item}))))
    dispatch(setLoading(false));
}

export const addListValue = (value, list_type = 1) => async (dispatch) => { //warn! hardcoded value!!!
    dispatch(setLoading(true));
    const response = await blackListsApi.addToList(list_type, value); 
    if(response){
        dispatch(getLists());
    } else {
        dispatch(setLoading(false)); // and set error!
    }
}

export const removeListValue = (value) => async (dispatch) => { 
    dispatch(setLoading(true));
    const response = await blackListsApi.deleteValue(value); 
    if(response){
        dispatch(getLists());
    } else {
        dispatch(setLoading(false)); // and set error!
    }
}