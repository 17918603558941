import { statsAPI } from "../api/stats-api";

const SET_DEVICE_STATS = 'SET_DEVICE_STATS';
const SET_USERS_STATS = 'SET_USERS_STATS';
const SET_REPORTS_STATS = 'SET_REPORTS_STATS';
const SET_LOADING = 'SET_LOADING';

const initialState = {
    deviceStats: [],
    usersStats: [],
    reportsStats: [],
    isLoading: false
}

export const StatisticsReducer = (state = initialState, action) => {
    switch (action.type){
        case SET_DEVICE_STATS:
        {
            return {...state, deviceStats: [...action.payload]};
        }
        case SET_USERS_STATS:
        {
            return {...state, usersStats: [...action.payload]};
        }
        case SET_REPORTS_STATS:
        {
            return {...state, reportsStats: [...action.payload]};
        }
        case SET_LOADING:
        {
            return {...state, isLoading: action.payload}; //isLoading = true | false
        }
        default :
        {
            return {...state}
        }
    }
}

const setDeviceStats = (payload) => ({type: SET_DEVICE_STATS, payload})
const setUsersStats = (payload) => ({type: SET_USERS_STATS, payload})
const setReportsStats = (payload) => ({type: SET_REPORTS_STATS, payload})
const setLoading = (payload) => ({type: SET_LOADING, payload}) //payload = true | false

const extractStatsData = (data, idValueName, statsParams ) => {

    let stats = []; // initialize empty array of statisctics data
    if(data){
        for(const timeblock of data){ //read array of data : [{timeblock},{timeblock},...]
            let itemsCount = timeblock[idValueName].length // get timeblock items length
            for(let i = 0; i < itemsCount; i++){ //get timeblock item data 
                let newRecord = {} // initialize timeblock item
                    for(const [key, value] of Object.entries(timeblock)){ //parse timeblock objects
                        if(typeof value === 'object'){ //check to array objects
                            newRecord[key] = value[i]; //read each timeblock object array value (from device_id: [1,2,3] get [1], then [2], then [3])
                        }
                    }
                const found = stats.findIndex(item => item[idValueName] === newRecord[idValueName]) //check if value already exsists in stats
                if(found >= 0){
                    for(const param of statsParams){
                        stats[found][param] += typeof newRecord[param] === 'number' ? newRecord[param] : `,${newRecord[param]}`//read all numeric params of item and add values to each other
                    }
                } else {
                    stats.push(newRecord);
                }
            }       
        } 
    }
    return stats;
}

export const getDeviceStats = (event_id,begin_time, end_time ) => async (dispatch) => {
    dispatch(setLoading(true));
    const response = await statsAPI.getDeviceStats(event_id,begin_time,end_time); 
    dispatch(setDeviceStats(extractStatsData(response.data, 'device_id', ['dev_cpu','dev_3d','dev_spec','url_click'])))
    dispatch(setLoading(false));
}

export const getUsersStats = (event_id,begin_time, end_time ) => async (dispatch) => {
    dispatch(setLoading(true));
    const response = await statsAPI.getUsersStats(event_id,begin_time,end_time); 
    dispatch(setUsersStats(extractStatsData(response.data, 'user_id', ['time_in_main_area','time_in_report_room','time_in_conf_room'])))
    dispatch(setLoading(false));
}

export const getReportsStats = (event_id,begin_time, end_time ) => async (dispatch) => {
    dispatch(setLoading(true));
    const response = await statsAPI.getReportsStats(event_id,begin_time,end_time); 
    dispatch(setReportsStats(extractStatsData(response.data, 'report_id', ['speakers','duration','viewers'])))
    dispatch(setLoading(false));
}
