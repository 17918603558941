import React from 'react';

const PartnerTwo = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdYzLwHeN2dWzBT6bXwYP8CJV9w8Qw0WT6wI-cF9j9wDSKUwQ/viewform?embedded=true"
                width="640" height="1376" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default PartnerTwo;
