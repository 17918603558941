import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import './Chat.css'
import { VoiceChatButtonsContext, WSSSContext } from '../../../utils/Context';
import { initializePeerConnection } from '../peer/Peer';
import { getVideoAudioStream } from '../../../utils/audioVideoContent/AudioVideoInitiator';
import { createVideo } from '../../../utils/audioVideoContent/VideoCreator';
import { getScreen } from '../../../utils/audioVideoContent/ScreenShareInitiator';
import { startSocketIO } from '../../socket/socketForAVChat';
import { mainServerRoot, pathForWebSocket } from '../../socket/constants';
import { io } from 'socket.io-client';
import ChatBar from './chat-bar/ChatBar';
import { ax } from '../../../../../api/api-setup';
import { timeFormatHelper } from '../../../utils/TimeFormatter';
import { axoftCompany } from '../../../components/socket/constants';
import { zones } from '../../../utils/zonesData/zonesData';
import { usePrevious } from './CustomHook/usePrevious';

export const Chat = () => {

	const recieveMsg = (msg) => {
		if (msg.origin === "https://s-dt2.cloud.gcore.lu") {
			// console.log(msg)
			setCurrentRoom(msg.data.uuid)
			setConnectedUsers(connectedUsers => connectedUsers.map((user, index) =>
				index === 0 ? { ...user, isMuted: false } : user))
			if (msg.data.cmd === 'zoneChange') {
				switch (msg.data.uuid) {
					case '0d4f60dd-d813-4b50-9e15-a838ca87b217': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '985c73ff-5956-4e33-a214-38d2bada0398': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '7c8723e0-f06f-4e2e-9e60-46f35eaba219': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '08051fd8-4737-4473-9243-45b7e04681c7': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '162575a8-3fe5-4199-b2c8-120950303290': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '64d4c92f-3580-4a09-a253-fda883e993a1': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'c1a580da-f27d-4334-9e82-69b3bab15dd7': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'e8d8a63b-0152-404d-ae81-02d0cf77ce89': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '956ccf93-fc1e-4458-8204-136dea4648ad': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '44a1b70e-b97f-4ada-a407-86abe65eae3d': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '2ce22fc7-8064-4a40-bc52-81c625f4353f': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '78fd4f42-ce8e-4987-8846-065b2be684ec': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'a66e36d1-dc3b-4f8f-b2ab-bef775515dd1': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '30725892-8ec9-4c92-926c-01c26b9048c6': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'd1fa6d56-f7cb-4146-bfa1-6f8ff31a4667': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '70e6f036-f30e-45ea-9102-978c6122e27d': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '5ca9334a-a319-4ebf-932f-84ecb32cb4af': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '58f2b5c9-69c5-487e-8eae-57d775ac68ee': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '027d83a4-eb94-4073-9960-c88775a3c591': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'e03cc66d-af1c-46d6-9979-8a6c0dc25660': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '3176de8f-3820-415b-9915-d45a88d015b3': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '4d9ac3ec-c99c-4d9d-ad7f-27a897188849': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '6cf12649-4b3a-4e90-8061-532e23112c4f': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'e5bb6c84-10bb-4bb8-9831-8f9cbeac4504': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '5417a229-9554-45d1-9489-61dffa4d2fcc': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '4932d763-eb2c-427d-a8b5-c191b0308de4': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '99942248-5ffd-444a-a6d2-ccabbe8dd3ce': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'e106f83c-5389-41e0-a12c-6d2a35a92c41': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'a7f4dd8a-52f4-4cf2-a85d-e69b25be0f63': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case '4bf74f88-c832-45ab-bdac-dec9517d4ea7': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					case 'c87e249f-1c36-4ecc-b96e-66c98e9fc69d': {
						socket.current.emit('joinEvent', msg.data.uuid);
						break;
					}
					default:
						console.log('Такой стенд отсутствует!')
				}
			}
			if (msg.data.cmd === 'handChange') {
				setHandRisen(handRisen => ({
					...handRisen, [Object.keys(msg.data)[1]]: Object.values(msg.data)[1]
				}))
			}
		} else {
			return
		}
	}

	useEffect(() => {
		window.addEventListener('message', recieveMsg, false)
	}, [])

	const { userUUID, sessionToken, userName, userLastName, userPhone, userEmail, userCompany, userPosition, userRole, userSecondName } = useContext(WSSSContext);

	const [peer, setPeer] = useState(null);
	const [isAudio, setIsAudio] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [isScreen, setIsScreen] = useState(false);
	const [messages, setMessages] = useState({});
	const [personalMessages, setPersonalMessages] = useState({});
	const [allMessages, setAllMessages] = useState([]);
	const [currentMessagesCount, setCurrentMessagesCount] = useState({});
	const [socketId, setSocketId] = useState('');
	let videoData = {};
	let connections = {};
	const [connectedUsers, setConnectedUsers] = useState([{
		peerUUID: `${userUUID}_${sessionToken.split('-')[0]}`,
		userUUID: userUUID,
		userName: userName,
		userSecondName: userSecondName,
		isMuted: false,
		state: {
			isTalking: false,
			isVideo: false,
		},
		userPhone: userPhone,
		userEmail: userEmail,
		userCompany: userCompany,
		userPosition: userPosition,
		userRole: userRole,
		userLastName: userLastName,
	}])
	const [isBadgeVisible, setIsBadgeVisible] = useState(false);
	const [innerStream, setInnerStream] = useState(null);
	const [modalIsVisible, setModalIsVisible] = useState(false)
	const [currentPeer, setCurrentPeer] = useState([]);
	const [peerIDsList, setPeerIDsList] = useState([]);
	const [roomConnected, setRoomConnected] = useState(`Общий чат_${axoftCompany}`);
	const [currentRoom, setCurrentRoom] = useState('');
	const [videoCounter, setVideoCounter] = useState(1);
	const [audioDevice, setAudioDevices] = useState([]);
	const [audioOutputDevice, setAudioOutputDevice] = useState([]);
	const [videoDevice, setVideoDevices] = useState([]);
	const [audioSelectValue, setAudioSelectValue] = useState(null);
	const [videoSelectValue, setVideoSelectValue] = useState(null);
	const [audioOutputSelectValue, setAudioOutputSelectValue] = useState(null);
	const [mutedAutomatically, setMutedAutomatically] = useState(true);
	const [constraintsAV, setConstraintsAV] = useState({
		audio: { deviceId: audioSelectValue ? { exact: audioSelectValue } : undefined },
		video: { deviceId: videoSelectValue ? { exact: videoSelectValue } : undefined }
	})
	const [constraintsA, setConstraintsA] = useState({
		audio: { deviceId: audioSelectValue ? { exact: audioSelectValue } : undefined },
	})
	const [connectType, setConnectType] = useState('')
	const [vCards, setVCards] = useState([]);
	const [handRisen, setHandRisen] = useState({
		[`${userUUID}_${sessionToken.split('-')[0]}`]: false
	})
	const [connectedUsersLength, setConnectedUsersLength] = useState(0)
	//refs
	const socket = useRef(null);
	const videoContainer = useRef();

	const prevMessagesCount = usePrevious(currentMessagesCount)

	const getStream = useCallback((id) => {
		getVideoAudioStream(constraintsAV).then((stream) => {
			console.log('Stream gotten');
			if (stream) {
				setConnectType('AV')
				setInnerStream(stream);

				peer.on('call', (call) => {
					const peerID = call.peer;
					if (peerID in connections) {
						return
					}

					connections[peerID] = {
						call: call,
						video: undefined,
						innerStream: undefined,
					}

					call.answer(stream);

					call.on('stream', (remoteStream) => {
						if (connections[peerID].innerStream !== undefined) return;
						setRemoteStream(remoteStream, call)
					});

					call.on('close', () => {
						console.log('closed');
					})
				})

				socket.current.emit('user_connected', socketId);
				setPeerIDsList([...peerIDsList, id]);
			}
		}).catch(err => {
			console.log('We could not connect your micro and video because ', err)
			disconnectWhenChangingRoom()

			getVideoAudioStream(constraintsA).then((stream) => {
				console.log('Stream gotten');
				if (stream) {

					const audioFromStream = stream.getAudioTracks()[0];
					const trackGenerator = new window.MediaStreamTrackGenerator({ kind: 'video' });
					const newStream = new MediaStream([audioFromStream, trackGenerator])

					setConnectType('A')
					setInnerStream(newStream);

					peer.on('call', (call) => {
						const peerID = call.peer;
						if (peerID in connections) {
							return
						}

						connections[peerID] = {
							call: call,
							video: undefined,
							innerStream: undefined,
						}
						window.rstream = newStream

						call.answer(newStream);

						call.on('stream', (remoteStream) => {
							if (connections[peerID].innerStream !== undefined) return;
							setRemoteStream(remoteStream, call)
						});

						call.on('close', () => {
							console.log('Call is closed');
						})
					})

					socket.current.emit('user_connected', socketId);
					setPeerIDsList([...peerIDsList, id]);
				}
			})
		}).then(getDevices).then(gotDevices)
	}, [peer, socket.current]);

	function getDevices() {
		// AFAICT in Safari this only gets default devices until gUM is called :/
		return navigator.mediaDevices.enumerateDevices();
	}

	function gotDevices(deviceInfos) {
		for (const deviceInfo of deviceInfos) {
			let option = {
				value: deviceInfo.deviceId
			}
			if (deviceInfo.kind === 'audioinput') {
				option.text = deviceInfo.label;
				setAudioDevices(audioDevice => [...audioDevice, option])
			} else if (deviceInfo.kind === 'videoinput') {
				option.text = deviceInfo.label;
				setVideoDevices(videoDevice => [...videoDevice, option])
			} else if (deviceInfo.kind === 'audiooutput') {
				option.text = deviceInfo.label;
				setAudioOutputDevice(audioOutputDevice => [...audioOutputDevice, option])
			}
		}
	}

	const initializePeerAndSockets = useCallback(() => {
		setAudioDevices([])
		setVideoDevices([])
		setAudioOutputDevice([])
		if (peer.destroyed) {
			setPeer(() => null)
			setPeerAndSocketsId()
		}
		peer.on('open', function (id) {
			console.log('Peer Opened');
			socket.current = io(`https://${mainServerRoot}:10010`, {
				path: pathForWebSocket,
			});

			startSocketIO(socket, peer, disconnectFromPeer);
			//initialize navigator 
			getStream(id);
		});

		peer.on('close', () => {
			console.log('Peer Connection closed');
		})

		peer.on('error', err => {
			console.log(err);
		})
	}, [peer, socket.current])

	const setPeerAndSocketsId = () => {
		setPeer(initializePeerConnection(userUUID, sessionToken));
		setSocketId(`${sessionToken}_9693986f-a0ad-4f50-9b98-4f740faa942c`)
	}

	const setRemoteStream = (remoteStream, call) => {
		const peerID = call.peer;
		let video = document.querySelector(`#vid-${peerID}`);

		//колхоз и неправильно (наверное). Нужно не удалять предыдущее видео, 
		//а просто не отправлять 2й раз
		if (connections[peerID].innerStream === undefined && !video) {
			createVideo(userUUID, videoData, { id: peerID, stream: remoteStream }, videoContainer.current, 0);
			setVideoCounter(prev => prev + 1);
			setCurrentPeer(currentPeer => [...currentPeer, call.peerConnection]);
			setPeerIDsList([...peerIDsList, call.peer]);
			connections[peerID].innerStream = remoteStream;
			connections[peerID].video = document.getElementById(`vid-${peerID}`);
		}
	}

	const disconnectFromPeer = (peerId) => {
		if (peerId in connections == false) {
			return
		}
		try {
			const uuid = peerId.split('_')[0];
			let video = document.getElementById(`vid-${peerId}`)
			setConnectedUsers(connectedUsers => connectedUsers.filter(user => user.userUUID !== uuid));

			video && video.remove();
			connections[peerId].connection && connections[peerId].connection.close();
			connections[peerId].conn && connections[peerId].conn.close();
			connections[peerId] = undefined;
			delete connections[peerId];
			delete videoData[peerId];
			setVideoCounter(prev => prev - 1);
			setHandRisen(handRisenPrev => {
				const newHandRisen = { ...handRisenPrev };
				delete newHandRisen[peerId]
				return newHandRisen
			})
		} catch (e) {
			console.log(e);
		}
	}

	const disconnectWhenChangingRoom = () => {
		setConnectedUsers(connectedUsers => [connectedUsers[0]]);
		let video = document.querySelectorAll('video')
		video.forEach(el => el && el.remove());

		for (let peerKey in connections) {
			connections[peerKey].connection && connections[peerKey].connection.close();
			connections[peerKey].conn && connections[peerKey].conn.close();
			delete connections[peerKey];
		}
		connections = {};
		videoData = {};
		setVideoCounter(1);
		setHandRisen(() => ({}))
	}

	const closeConnections = useCallback(() => {
		disconnectWhenChangingRoom();

		socket.current && socket.current.removeAllListeners('user_connected');
		socket.current && socket.current.removeAllListeners('open');
		socket.current && socket.current.removeAllListeners('chatMessage');
		socket.current && socket.current.removeAllListeners('connected_result');
		socket.current && socket.current.removeAllListeners('connected_to');
		socket.current && socket.current.removeAllListeners('user_disconnected');
		socket.current && socket.current.removeAllListeners('callPeer');
		socket.current && socket.current.removeAllListeners('setPeerState');
		socket.current && socket.current.close();
		peer && peer.destroy();
		if (innerStream) {
			innerStream.getTracks().forEach(track => {
				track.stop();
			});
		}
	}, [peer, socket.current, innerStream, setPeer])

	const startCapture = () => {
		setIsScreen(true);
	}

	const connectAndCall = (remotePeerId, stream) => {
		if (remotePeerId in connections) {
			return
		}
		let call = peer.call(remotePeerId, stream);
		connections[remotePeerId] = {
			call: call,
			video: undefined,
			innerStream: undefined,
			conn: peer.connect(remotePeerId)
		}

		call.on('stream', (remoteStream) => {
			if (connections[remotePeerId].innerStream !== undefined) return;
			setRemoteStream(remoteStream, call)
		});

		call.on('close', () => {
		})
	}

	const stopCapture = () => {
		let me = `${userUUID}_${sessionToken.split('-')[0]}`;
		socket.current.emit('setPeerState', { state: { isVideo: false }, peerUUID: me });
		const videoTrack = innerStream.getVideoTracks()[0];
		currentPeer.forEach(peer => peer.getSenders().find(s => s.track.kind === videoTrack.kind).replaceTrack(videoTrack))
		setIsScreen(false);
	}

	function toBoolean(el) {
		return el === true;
	}

	useEffect(() => {
		if (videoCounter > 1 && audioOutputSelectValue !== null) {
			let videos = document.querySelectorAll('video');
			videos.forEach(vid => vid.setSinkId(audioOutputSelectValue));
		}
	}, [videoCounter, audioOutputSelectValue])

	useEffect(() => {
		if (socket.current && innerStream) {
			socket.current.on('user_connected', (args) => {
				console.log('User connected => ', args)
				if (args.type === 'voice') {
					if (args !== peer.id) {
						setHandRisen(handRisen => ({ ...handRisen, [args.user]: false }))
						if (localStorage.getItem(args.user)) {
							let user = JSON.parse(localStorage.getItem(args.user));
							let keyName = `${user.userLastName} ${user.userName}_${args.user}`;

							setConnectedUsers(connectedUsers => [...connectedUsers, user])

							setPersonalMessages(personalMessages => personalMessages[keyName] ? ({ ...personalMessages }) : ({ ...personalMessages, [keyName]: [] }))

							setTimeout(() => {
								connectAndCall(args.user, innerStream)
							}, 1000);
						} else {
							const uuid = args.user.split('_')[0];

							ax.post(`/api/account/show-user-info`,
								{ uuid: uuid },
								{
									headers: {
										'x-viexpo-session-token': localStorage.getItem('token'),
									},
								})
								.then(res => {
									const data = res.data.user;
									const user = {
										userUUID: data.user_uuid,
										userName: data.first_name,
										userSecondName: data.secon_name,
										userLastName: data.last_name,
										peerUUID: args.user,
										isMuted: false,
										state: {
											isTalking: args.state.isTalking,
											isVideo: args.state.isVideo,
										},
										userPhone: data.phone,
										userEmail: data.email,
										userCompany: data.company,
										userPosition: data.position,
										userRole: data.system_role
									};
									let keyName = `${data.last_name} ${data.first_name}_${args.user}`;

									localStorage.setItem(args.user, JSON.stringify(user))

									setConnectedUsers(connectedUsers => [...connectedUsers, user])

									setPersonalMessages(personalMessages => personalMessages[keyName] ? ({ ...personalMessages }) : ({ ...personalMessages, [keyName]: [] }))

									setTimeout(() => {
										connectAndCall(args.user, innerStream)
									}, 1000);
								})
						}
					}
				}
			})

			socket.current.on('connected_to', (args) => {
				console.log('Connected to room => ', args);
				let newKey = `${zones[args.room]}_${args.room}`
				if (args.type === 'chat') {
					if ((newKey in messages) === false && (args.room in zones)) {
						setMessages(messages => messages[newKey] ? ({ ...messages }) : ({ ...messages, [newKey]: [] }))
					}
				}
				if (args.type === 'voice') {
					disconnectWhenChangingRoom()
					setRoomConnected(newKey);
					setTimeout(function () {
						for (let remotePeerID of args.users) {
							if (remotePeerID !== peer.id) {
								setHandRisen(handRisen => ({ ...handRisen, [remotePeerID]: false }))
								if (localStorage.getItem(remotePeerID)) {
									let user = JSON.parse(localStorage.getItem(remotePeerID));
									let keyName = `${user.userLastName} ${user.userName}_${remotePeerID}`;

									setConnectedUsers(connectedUsers => [...connectedUsers, user])

									setPersonalMessages(personalMessages => personalMessages[keyName] ? ({ ...personalMessages }) : ({ ...personalMessages, [keyName]: [] }))

									setTimeout(() => {
										connectAndCall(remotePeerID, innerStream)
									}, 1000);
								} else {
									const uuid = remotePeerID.split('_')[0];
									ax.post(`/api/account/show-user-info`,
										{ uuid: uuid },
										{
											headers: {
												'x-viexpo-session-token': localStorage.getItem('token'),
											},
										})
										.then(res => {
											const data = res.data.user;
											const user = {
												userUUID: data.user_uuid,
												userName: data.first_name,
												userSecondName: data.secon_name,
												userLastName: data.last_name,
												peerUUID: remotePeerID,
												isMuted: false,
												state: {
													isTalking: args.states[remotePeerID].isTalking,
													isVideo: args.states[remotePeerID].isVideo,
												},
												userPhone: data.phone,
												userEmail: data.email,
												userCompany: data.company,
												userPosition: data.position,
												userRole: data.system_role
											};
											let keyName = `${data.last_name} ${data.first_name}_${remotePeerID}`;

											localStorage.setItem(args.user, JSON.stringify(user))

											setConnectedUsers(connectedUsers => [...connectedUsers, user])

											setPersonalMessages(personalMessages => personalMessages[keyName] ? ({ ...personalMessages }) : ({ ...personalMessages, [keyName]: [] }))
											setTimeout(() => {
												connectAndCall(remotePeerID, innerStream)
											}, 1000);
										})
								}
							}
						}
					}, 2000)
				}
			})

			socket.current.on('chatMessage', (mess) => {
				let me = `${userUUID}_${sessionToken.split('-')[0]}`;
				let newKey = `${zones[mess.room]}_${mess.room}`

				if ((newKey in messages) === false) {
					setMessages(messages => ({
						...messages, ...messages[newKey].push({
							me: mess.from.peerUUID === me ? true : false,
							currentMinutes: timeFormatHelper(new Date().getMinutes()),
							currentHour: timeFormatHelper(new Date().getHours()),
							message: mess.message,
							name: mess.from.name,
							lastName: mess.from.last_name,
							read: mess.from.peerUUID === me ? true : false
						})
					}))
				} else {
					setMessages(messages => ({
						...messages, [newKey]: [{
							me: mess.from.peerUUID === me ? true : false,
							currentMinutes: timeFormatHelper(new Date().getMinutes()),
							currentHour: timeFormatHelper(new Date().getHours()),
							message: mess.message,
							name: mess.from.name,
							lastName: mess.from.last_name,
							read: mess.from.peerUUID === me ? true : false
						}]
					}))
				}
			})

			socket.current.on('callPeer', arg => {
				let confirmation = window.confirm(`${arg.from.last_name} ${arg.from.name} вызывает на приватный звонок. Подключиться?`);
				if (confirmation) {
					setCurrentRoom(roomConnected.split('_')[1])
					disconnectWhenChangingRoom()
					setTimeout(() => {
						socket.current.emit('joinLobby', arg.to)
					}, 1000);
				}
			})

			socket.current.on('sendTo', (data) => {
				let me = `${userUUID}_${sessionToken.split('-')[0]}`;

				//check for income personal message
				//and set it to the object
				if (data.packet.pmess && data.from !== me) {
					let keyName = `${data.packet.pmess.last_name} ${data.packet.pmess.name}_${data.from}`;
					if ((keyName in personalMessages) === false) {
						setPersonalMessages(personalMessages => ({
							...personalMessages, ...personalMessages[keyName].push({
								me: false,
								currentMinutes: timeFormatHelper(new Date().getMinutes()),
								currentHour: timeFormatHelper(new Date().getHours()),
								message: data.packet.pmess.message,
								name: data.packet.pmess.name,
								lastName: data.packet.pmess.last_name,
								read: false
							})
						}))
					}
					// else {
					// 	setPersonalMessages(personalMessages => ({
					// 		...personalMessages, [keyName]: [{
					// 			me: false,
					// 			currentMinutes: timeFormatHelper(new Date().getMinutes()),
					// 			currentHour: timeFormatHelper(new Date().getHours()),
					// 			message: data.packet.pmess.message,
					// 			name: data.packet.pmess.name,
					// 			lastName: data.packet.pmess.last_name
					// 		}]
					// 	}))
					// }
				}

				//visit card
				if (data.packet.vcard && data.from !== me) {
					const keyName = `${data.packet.vcard.userLastName} ${data.packet.vcard.userName}_${data.from}`;

					setPersonalMessages(personalMessages => ({
						...personalMessages, ...personalMessages[keyName].push({
							isCard: data.packet.vcard.isCard,
							peerUUID: data.from,
							userPhone: data.packet.vcard.userPhone,
							userEmail: data.packet.vcard.userEmail,
							userCompany: data.packet.vcard.userCompany,
							userPosition: data.packet.vcard.userPosition,
							userLastName: data.packet.vcard.userLastName,
							userName: data.packet.vcard.userName,
							userSecondName: data.packet.vcard.userSecondName,
							currentMinutes: timeFormatHelper(new Date().getMinutes()),
							currentHour: timeFormatHelper(new Date().getHours()),
							read: false
						})
					}))

					setVCards(vCards => ([...vCards, {
						peerUUID: data.from,
						userPhone: data.packet.vcard.userPhone,
						userEmail: data.packet.vcard.userEmail,
						userCompany: data.packet.vcard.userCompany,
						userPosition: data.packet.vcard.userPosition,
						userLastName: data.packet.vcard.userLastName,
						userName: data.packet.vcard.userName,
						userSecondName: data.packet.vcard.userSecondName
					}]))
				}

				//mute\unmute by admin
				if (data.packet.unmute && data.from !== me) {
					setConnectedUsers(connectedUsers => connectedUsers.map((u, i) => i === 0 ? ({ ...u, isMuted: data.packet.unmute.state }) : u))
					setMutedAutomatically(data.packet.unmute.state)
				}
			})

			socket.current.on('setPeerState', args => {
				const peerUUID = args.from;

				setConnectedUsers(connectedUsers => connectedUsers.map(user =>
					user.peerUUID === peerUUID ? { ...user, state: args.state } : user))
			})

			console.log(socket.current)
		}
	}, [innerStream])

	// useEffect(() => {
	// 	console.log(personalMessages, messages, roomConnected, socket.current, peer)
	// }, [personalMessages, messages, roomConnected, socket.current, peer])

	//constraints controll

	useEffect(() => {
		setConstraintsAV({
			audio: { deviceId: audioSelectValue ? { exact: audioSelectValue } : undefined },
			video: { deviceId: videoSelectValue ? { exact: videoSelectValue } : undefined }
		})
	}, [audioSelectValue, videoSelectValue])
	useEffect(() => {
		setConstraintsA({
			audio: { deviceId: audioSelectValue ? { exact: audioSelectValue } : undefined },
		})
	}, [audioSelectValue])

	//badge control
	useEffect(() => {
		setAllMessages({ ...messages, ...personalMessages })
	}, [messages, personalMessages])

	useEffect(() => {
		let allMessagesCount = {};

		for (let key in allMessages) {
			allMessagesCount = {
				...allMessagesCount,
				[key]: {
					count: allMessages[key].length,
				}
			}
		}

		setCurrentMessagesCount(allMessagesCount);

	}, [allMessages])

	// useEffect(() => {
	// 	console.log(allMessages)
	// 	Object.keys(messages).map(sender => messages[sender].map(message => message.read ? setIsBadgeVisible(false) : setIsBadgeVisible(true)))
	// 	Object.keys(personalMessages).map(sender => personalMessages[sender].map(message => message.read ? setIsBadgeVisible(false) : setIsBadgeVisible(true)))
	// }, [messages, personalMessages])

	useEffect(() => {
		let mess = [false];
		let pmess = [false];

		for (let key in messages) {
			messages[key].map(message => message.read ? mess.push(false) : mess.push(true))
		}
		for (let key in personalMessages) {
			personalMessages[key].map(message => message.read ? pmess.push(false) : pmess.push(true))
		}

		setIsBadgeVisible(mess.some(toBoolean) !== pmess.some(toBoolean))
	}, [messages, personalMessages, setMessages, setPersonalMessages])

	//initialize peer
	useEffect(() => {
		if (userUUID && sessionToken) {
			setPeerAndSocketsId();
		}
	}, [userUUID, sessionToken])

	//set connected users length
	useEffect(() => {
		setConnectedUsersLength(connectedUsersLength => connectedUsers.length)
	}, [connectedUsers])

	useEffect(() => {
		console.log('Connected users => ', connectedUsers);
		connectedUsers.map((u, idx) => {
			let userVideo = document.getElementById(`vid-${u.peerUUID}`);

			if (u.state.isVideo === true && idx !== 0 && userVideo !== null) {
				userVideo.style.display = 'block'
			}
			if (u.state.isVideo === false && idx !== 0 && userVideo !== null) {
				userVideo.style.display = 'none'
			}
		})

	}, [connectedUsers])

	useEffect(() => {
		if (peer) {
			initializePeerAndSockets();
		}
		return () => {
			//closing all connections on component unmount
			closeConnections();
		}
	}, [peer])

	useEffect(() => {
		return () => {
			innerStream && innerStream.getTracks().forEach(track => track.stop());
		}
	}, [innerStream])

	//replace videostream and start screensharing
	useEffect(() => {
		if (isScreen) {

			getScreen().then((stream) => {
				if (stream) {
					let me = `${userUUID}_${sessionToken.split('-')[0]}`;
					socket.current.emit('setPeerState', { state: { isVideo: true }, peerUUID: me });
					const videoTrack = stream.getVideoTracks()[0];
					videoTrack.onended = () => {
						stopCapture();
					};
					currentPeer.forEach(peer => peer.getSenders().find(s => s.track.kind === videoTrack.kind).replaceTrack(videoTrack));
				}
			}).catch(err => {
				console.log('Unable to get display media ' + err);
			})
		}
	}, [isScreen])

	//Control of inner sound and video tracks. Mute by default

	useEffect(() => {
		if (!innerStream) return;
		innerStream.getVideoTracks().forEach(track => track.enabled = isVideo);
		if (socket.current) {
			let me = `${userUUID}_${sessionToken.split('-')[0]}`;
			socket.current.emit('setPeerState', { state: { isVideo: isVideo }, peerUUID: me });
		}
	}, [innerStream, isVideo, socket.current]);

	useEffect(() => {
		if (!innerStream) return;
		innerStream.getAudioTracks().forEach(track => track.enabled = isAudio);

		if (socket.current) {
			let me = `${userUUID}_${sessionToken.split('-')[0]}`
			socket.current.emit('setPeerState', { state: { isTalking: isAudio }, peerUUID: me })
		}
	}, [innerStream, isAudio, socket.current]);

	//context for buttons
	const [checked, setChecked] = useState(false);

	const [voiceChatCtx, setVoiceChatCtx] = useState({
		isAudio: null,
		setIsAudio: null,
		isVideo: null,
		setIsVideo: null,
		isScreen: null,
		startCapture: null,
		stopCapture: null,
		socket: null,
		messages: null,
		setMessages: null,
		connectedUsers: null,
		setConnectedUsers: null,
		checked: null,
		setChecked: null,
		roomConnected: null,
		currentRoom: null,
		setCurrentRoom: null,
		isBadgeVisible: null,
		setIsBadgeVisible: null,
		modalIsVisible: null,
		setModalIsVisible: null,
		audioDevice: null,
		videoDevice: null,
		setAudioSelectValue: null,
		setVideoSelectValue: null,
		initializePeerAndSockets: null,
		closeConnections: null,
		audioSelectValue: null,
		videoSelectValue: null,
		audioOutputDevice: null,
		audioOutputSelectValue: null,
		setAudioOutputSelectValue: null,
		mutedAutomatically: null,
		personalMessages: null,
		setPersonalMessages: null,
		vCards: null,
		currentMessagesCount: null,
		prevMessagesCount: null,
		allMessages: null,
		setAllMessages: null,
		handRisen: null,
		connectedUsersLength: null,
	})

	useEffect(() => {
		if (socket.current) {
			setVoiceChatCtx({
				isAudio: isAudio,
				setIsAudio: setIsAudio,
				isVideo: isVideo,
				setIsVideo: setIsVideo,
				isScreen: isScreen,
				startCapture: startCapture,
				stopCapture: stopCapture,
				socket: socket.current,
				messages: messages,
				setMessages: setMessages,
				connectedUsers: connectedUsers,
				setConnectedUsers: setConnectedUsers,
				checked: checked,
				setChecked: setChecked,
				roomConnected: roomConnected,
				currentRoom: currentRoom,
				setCurrentRoom: setCurrentRoom,
				isBadgeVisible: isBadgeVisible,
				setIsBadgeVisible: setIsBadgeVisible,
				modalIsVisible: modalIsVisible,
				setModalIsVisible: setModalIsVisible,
				audioDevice: audioDevice,
				videoDevice: videoDevice,
				setAudioSelectValue: setAudioSelectValue,
				setVideoSelectValue: setVideoSelectValue,
				initializePeerAndSockets: initializePeerAndSockets,
				closeConnections: closeConnections,
				audioSelectValue: audioSelectValue,
				videoSelectValue: videoSelectValue,
				audioOutputDevice: audioOutputDevice,
				audioOutputSelectValue: audioOutputSelectValue,
				setAudioOutputSelectValue: setAudioOutputSelectValue,
				mutedAutomatically: mutedAutomatically,
				personalMessages: personalMessages,
				setPersonalMessages: setPersonalMessages,
				vCards: vCards,
				currentMessagesCount: currentMessagesCount,
				prevMessagesCount: prevMessagesCount,
				allMessages: allMessages,
				setAllMessages: setAllMessages,
				handRisen: handRisen,
				connectedUsersLength: connectedUsersLength
			})
		}
	}, [peer, isAudio, isVideo, isScreen, messages, userUUID, sessionToken, socket.current, connectedUsers, checked, roomConnected, isBadgeVisible, modalIsVisible, audioDevice, audioSelectValue, videoSelectValue, audioOutputDevice, audioOutputSelectValue, mutedAutomatically, personalMessages, vCards, currentMessagesCount, allMessages, handRisen, connectedUsersLength])

	return (
		<VoiceChatButtonsContext.Provider value={voiceChatCtx}>
			<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', }}>
				{socket.current && <ChatBar />}
				<Box id='videosContainer' ref={videoContainer} sx={{
					position: 'absolute',
					top: 0,
					left: '400px',
					width: '555px',
					backgroundColor: ' white',
					overflow: 'scroll',
					maxHeight: '81vh',
					display: 'flex',
					visibility: checked ? 'visible' : 'hidden',
					flexDirection: 'row',
					flexWrap: 'wrap',
					alignItems: 'flex-start',
					justifyContent: 'space-evenly',
					zIndex: checked ? 1 : 0,
				}} />
			</Box >
		</VoiceChatButtonsContext.Provider >
	);
}