/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
//import { QuickUserToggler } from "../../extras/QuickUserToggler";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { role } = useSelector(state => ({
    role: state.auth.user.system_role
  }),
    shallowEqual
  );

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text">Главная</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/*<li
          className={`menu-item ${getMenuItemActive("/builder", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Layout Builder</span>
          </NavLink>
        </li>*/}
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        {/*<li className="menu-section ">
          <h4 className="menu-text">Администрирование</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>*/}
        {/* end:: section */}

        {/* Bootstrap */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/admin",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          {/* check is admin, hide*/}
          {role === 1 || role === 0 &&
            <NavLink className="menu-link menu-toggle" to="/admin">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Администрирование</span>
              <i className="menu-arrow" />
            </NavLink>}
          <div className="menu-submenu">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                {/*<li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Администрирование</span>
                  </span>
                </li>*/}

                {/*begin::1 Level*/}
                {/* <li
                    className={`menu-item ${getMenuItemActive("/admin/events", false)}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/events">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Pixels.svg")} />
            </span>
                    <span className="menu-text">Мероприятия</span>
                  </NavLink>
                </li> */}
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/admin/customers", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/customers">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                    </span>
                    <span className="menu-text">Пользователи</span>
                  </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/admin/reports", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/reports">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                    </span>
                    <span className="menu-text">Доклады</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/admin/stands", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/stands">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Stand.svg")} />
                    </span>
                    <span className="menu-text">Стенды</span>
                  </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::1 Level*/}
                {/* <li
                  className={`menu-item ${getMenuItemActive("/admin/settings", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/settings">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")} />
                    </span>
                    <span className="menu-text">Настройки</span>
                  </NavLink>
                </li> */}
                {/*end::1 Level*/}

                {/*begin::2 Level*/}
                {/*<li
                  className={`menu-item ${getMenuItemActive(
                    "/react-bootstrap/carousel"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/react-bootstrap/carousel">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Carousel</span>
                  </NavLink>
                </li>*/}
                {/*end::2 Level*/}
              </ul>
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}


        <li
          className={`menu-item ${getMenuItemActive("/pools", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pools">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} />
            </span>
            <span className="menu-text">Опросы</span>
          </NavLink>
        </li>




        {/* STATISCTICS LEVEL 1 START */}
        {/* <li className={`menu-item menu-item-submenu ${getMenuItemActive("/statistics", true)}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
    >
      {role === 1 &&
      <NavLink className="menu-link menu-toggle" to="/statistics">
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")} />
        </span>
        <span className="menu-text">Статистика</span>
        <i className="menu-arrow" />
      </NavLink> }

      <div className="menu-submenu">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                  <li
                      className={`menu-item ${getMenuItemActive("/statistics/users", false)}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/statistics/users">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/statistics/Users.svg")} />
                      </span>
                      <span className="menu-text">Пользователи</span>
                    </NavLink>
                  </li>
                  <li
                      className={`menu-item ${getMenuItemActive("/statistics/interactive", false)}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/statistics/interactive">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/statistics/Interactive.svg")} />
                      </span>
                      <span className="menu-text">Интерактивные объекты</span>
                    </NavLink>
                  </li>
                  <li
                      className={`menu-item ${getMenuItemActive("/statistics/reports", false)}`}
                      aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/statistics/reports">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/statistics/Report.svg")} />
                      </span>
                      <span className="menu-text">Доклады</span>
                    </NavLink>
                  </li>
              </ul>
            </ul>
        </div>
    </li> */}
        {/* STATISCTICS LEVEL 1 END */}



        {/*begin::1 Level*/}
         <li
              className={`menu-item ${getMenuItemActive("/documentation", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/documentation">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                    </span>
                  <span className="menu-text">Документация</span>
              </NavLink>
          </li>
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        {/*<li className="menu-section">
          <h4 className="menu-text">Мероприятия</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>*/}
        {/* end:: section */}



        {/*begin::1 Level*/}
        {/*<li
          className={`menu-item ${getMenuItemActive("/build", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/build">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Code/Done-circle.svg"
                )}
              />
            </span>
            <span className="menu-text">Открытое мероприятие</span>
          </NavLink>
        </li>*/}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/*<li
              className={`menu-item ${getMenuItemActive("/webglbuild", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/webglbuild">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Error-circle.svg"
                  )}
              />
            </span>
                  <span className="menu-text">Закрытое мероприятие</span>
              </NavLink>
          </li>*/}
        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        {/*<li className="menu-section ">
          <h4 className="menu-text">Custom</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>*/}
        {/* end:: section */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
