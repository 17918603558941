import React from 'react';

const PartnerCard = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdejh5o4HG8zp7_HPKa7hRg40V22tBtsvg157irY5TmkTE0WQ/viewform?embedded=true"
                width="100%" height="1247" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default PartnerCard;
