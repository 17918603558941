import { usersAPI } from "../api/api";
import { getCurrentSession } from '../redux/auth-reducer'
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
const SET_USERS = 'SET_USERS';
const SET_FILTER_STATUS = 'SET_FILTER_STATUS';
const SET_FILTER_ROLE = 'SET_FILTER_ROLE';
const SET_EDIT_USER_DATA = 'SET_EDIT_USER_DATA';
const CLEAR_EDIT_CUSTOMER = 'CLEAR_EDIT_CUSTOMER';
const SET_AVATAR_UPLOADING = 'SET_AVATAR_UPLOADING';
const SET_AVATAR_FORMDATA = 'SET_AVATAR_FORMDATA';
const SET_NEW_PHOTO = 'SET_NEW_PHOTO';
const CLEAR_PHOTO = 'CLEAR_PHOTO';
const SET_PHOTO_ALERT = 'SET_PHOTO_ALERT';

const AK_SET_USERS = 'AK_SET_USERS'
const AK_SELECTED_USER = 'AK_SELECTED_USER'
const AK_CREATE_USER = 'AK_CREATE_USER'
const AK_ACTION_FETCH_START = 'AK_ACTION_FETCH_START'
const AK_ACTION_FETCH_END = 'AK_ACTION_FETCH_END'

const InitialState = {
    ak_users: [],
    ak_selected_user: null,
    ak_action_fetching: false,

    users: [],
    customerForEdit: {
        id: null,
        first_name: "",
        last_name: "",
        password: "",
        patronymic: "",
        login: "",
        phone: "",
        company: "",
        roleID: 3,
        job: "",
        avatar_url: null,
        agreement: false,
        enabled: false,
        //character_avatar: 1
    },
    totalCount: 0,
    entities: [],
    isFetching: true,
    listLoading: false,
    isAvatarUploading: false,
    avatarFormData: null,
    filterStatus: "-1", // -1 all, 0 disabled, 1 enabled
    filterRole: "-1", // -1 all, 1 - 3 admin,speaker,guest
    photoAlert: null
}

const usersReducer = (state = InitialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return { ...state, entities: [...action.users], totalCount: action.users.length }
        case AK_SET_USERS: {
            return {
                ...state,
                ak_users: action.payload,
            }
        }
        case AK_SELECTED_USER: {
            return {
                ...state,
                ak_selected_user: action.payload,
            }
        }
        case AK_ACTION_FETCH_START: {
            return {
                ...state,
                ak_action_fetching: true,
            }
        }
        case AK_ACTION_FETCH_END: {
            return {
                ...state,
                ak_action_fetching: false,
            }
        }
        case SET_PHOTO_ALERT:
            return { ...state, photoAlert: action.payload }
        case SET_NEW_PHOTO:
            return {
                ...state,
                customerForEdit: {
                    ...state.customerForEdit,
                    avatar_url: encodeURI(`${action.payload[2]}${action.payload[1]}`)
                }
            }
        case CLEAR_PHOTO:
            return {
                ...state,
                customerForEdit: {
                    ...state.customerForEdit,
                    avatar_url: "lnk"
                }
            }
        case SET_FILTER_STATUS:
            return { ...state, filterStatus: action.payload }
        case SET_FILTER_ROLE:
            return { ...state, filterRole: action.payload }
        case TOGGLE_IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        case SET_AVATAR_UPLOADING:
            return { ...state, isAvatarUploading: action.isLoading }
        case CLEAR_EDIT_CUSTOMER:
            return {
                ...state, customerForEdit: {
                    id: null,
                    first_name: "",
                    last_name: "",
                    patronymic: "",
                    login: "",
                    password: "",
                    phone: "",
                    company: "",
                    roleID: 3,
                    job: "",
                    avatar_url: null,
                    agreement: false,
                    enabled: false,
                }
            }
        case SET_EDIT_USER_DATA:
            return {
                ...state,
                customerForEdit: {
                    id: action.payload.id,
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    patronymic: action.payload.patronymic,
                    login: action.payload.login,
                    phone: action.payload.phone,
                    company: action.payload.company,
                    roleID: action.payload.roles.type,
                    job: action.payload.job,
                    avatar_url: encodeURI(`${action.payload.photo_url + action.payload.photo_key}`),
                    agreement: action.payload.agreement,
                    enabled: action.payload.enabled,
                    //character_avatar: action.payload.character_avatar,
                    password: ""
                }
            }
        case SET_AVATAR_FORMDATA:
            return { ...state, avatarFormData: action.formdata }
        default:
            return state;
    }
}

const setUserEditData = (payload) => ({ type: SET_EDIT_USER_DATA, payload });
const setFilterStatus = (payload) => ({ type: SET_FILTER_STATUS, payload }); //payload = string "-1", "0", "1"
const setFilterRole = (payload) => ({ type: SET_FILTER_ROLE, payload }); //payload = string "-1", "1", "2", "3"
const updatePhoto = (payload) => ({ type: SET_NEW_PHOTO, payload }); //
const clearPhoto = () => ({ type: CLEAR_PHOTO }); //
export const setPhotoAlert = (payload) => ({ type: SET_PHOTO_ALERT, payload }); // alert: null | onChange | onDelete | onError
export const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });
export const setUsers = (users) => ({ type: SET_USERS, users });
export const setAvatarUploading = (isLoading) => ({ type: SET_AVATAR_UPLOADING, isLoading })
export const setAvatarFormdata = (formdata) => ({ type: SET_AVATAR_FORMDATA, formdata })
export const clearEditCustomer = () => ({ type: CLEAR_EDIT_CUSTOMER });

// aksoft
export const ak_setUsers = (payload) => ({
    type: AK_SET_USERS,
    payload,
})

export const ak_setSelectedUsers = (payload) => ({
    type: AK_SELECTED_USER,
    payload,
})

export const ak_actionFetchStart = () => ({
    type: AK_ACTION_FETCH_START,
})

export const ak_actionFetchEnd = () => ({
    type: AK_ACTION_FETCH_END,
})

export const requestUsers = () => async (dispatch) => {
    try {
        dispatch(ak_actionFetchStart())
        const response = await usersAPI.getUsers();
        //console.log('response', response);

        if (response.data) {
            dispatch(ak_setUsers(response.data.response));
        }
    } catch {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const createUser = (data) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart())
        const postData = {
            ...data,
            shtani: Number(data.shtani),
            system_role: Number(data.system_role),
            system_status: Number(data.system_status),
        }

        const result = await usersAPI.createUser(postData);
        const state = getState()

        if (result.data.code === 200) {
            const newUsers = [...state.customers.ak_users]
            newUsers.push({
                ...postData,
                uuid: postData.uuid,
            })
            dispatch(ak_setUsers(newUsers));
            return true;
        }

        return false;
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const updateUserByAdmin = (data) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart())
        const postData = {
            ...data,
            system_status: Number(data.system_status),
            system_role: Number(data.system_role),
            shtani: Number(data.shtani),
            birthday: '1900-01-01 12:00:00',
            user_uuid: data.uuid,
        }

        const result = await usersAPI.changeUser(postData);

        //console.log('updateUserByAdmin result', result)

        if (result.data.code === 200) {
            const state = getState()
            const newUsers = state.customers.ak_users.map(user => {
                if (user.uuid === postData.uuid) {
                    return postData;
                }

                return user;
            });
            //console.log('newUsers', newUsers)

            dispatch(ak_setUsers(newUsers));

            return true;
        }

        return false;
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const changeUserPassword = (uuid, new_password) => async (dispatch) => {
    try {
        dispatch(ak_actionFetchStart())
        const result = await usersAPI.changeUserPassword(uuid, new_password);

        if (result.data.code === 200) {
            return true;
        }

        return false;
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const changeUserRole = (uuid, new_role) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart())

        const result = await usersAPI.changeUserRole(uuid, Number(new_role))
        const state = getState()

        if (result.data.code === 200) {
            const newUsers = state.customers.ak_users.map(user => {
                if (user.uuid === uuid) {
                    return {
                        ...user,
                        system_role: Number(new_role)
                    }
                }
                return user
            });

            dispatch(ak_setUsers(newUsers));
            return true;
        }
        return false;
    } catch (e) {
        return false;
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const unblockUser = (uuid) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart())

        const result = await usersAPI.unblockUser(uuid)
        const state = getState()

        if (result.data.code === 200) {
            const newUsers = state.customers.ak_users.map(user => {
                if (user.uuid === uuid) {
                    return {
                        ...user,
                        system_status: 1
                    }
                }
                return user
            });

            dispatch(ak_setUsers(newUsers));
        }
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const blockUser = (uuid) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart())

        const result = await usersAPI.blockUser(uuid)
        const state = getState()

        if (result.data.code === 200) {
            const newUsers = state.customers.ak_users.map(user => {
                if (user.uuid === uuid) {
                    return {
                        ...user,
                        system_status: 2
                    }
                }
                return user
            });
            dispatch(ak_setUsers(newUsers));
        }
    } catch (e) {
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const removeUser = (uuid) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart())

        const result = await usersAPI.removeUser(uuid);

        const state = getState()

        if (result.data.code === 200) {
            const newUsers = state.customers.ak_users.filter(user => user.uuid !== uuid);
            dispatch(ak_setUsers(newUsers));
            return true;
        }
        return false;
    } catch (e) {
        return false;
    } finally {
        dispatch(ak_actionFetchEnd())
    }
}

export const getUserById = (id) => async (dispatch, getState) => {
    try {
        dispatch(ak_actionFetchStart());

        const result = await usersAPI.getUser(id);

        if (result.data.code === 200) {
            const state = getState()

            const newUsers = state.users.ak_users.map(user => {
                if (user.uuid === id) {
                    return result.data.user;
                }

                return user;
            })

            dispatch(ak_setUsers(newUsers))

            if (state.users.ak_selected_user.uuid === id) {
                dispatch(ak_setSelectedUsers(result.data.user))
            }
        }
    } catch {
    } finally {
        dispatch(ak_actionFetchEnd());
    }
}
//

export const setTableFilterStatus = (status) => dispatch => {
    localStorage.setItem('filterStatus', status);
    dispatch(setFilterStatus(status));
}

export const setTableFilterRole = (role) => dispatch => {
    localStorage.setItem('filterRole', role);
    dispatch(setFilterRole(role));
}

export const initFilters = () => dispatch => {
    let status = localStorage.getItem('filterStatus');
    let role = localStorage.getItem('filterRole');
    dispatch(setFilterStatus(status || "-1")); // "-1" default value == all
    dispatch(setFilterRole(role || "-1")); //"-1" default value == all
}

export const clearCustomerFields = () => async (dispatch) => {
    await Promise.all([
        dispatch(clearEditCustomer())
    ]);
}

export const addUser = (userData) => async () => {
    await Promise.all([
        usersAPI.addUser(userData)
    ]);
}

export const addGuest = (userData) => async () => {
    await Promise.all([
        usersAPI.addGuest(userData)
    ]);
}

export const updatePassword = (password, id) => async () => {
    await Promise.all([
        usersAPI.updatePassword(password, id)
    ]);
}

export const deleteUser = (userId) => async () => {
    await Promise.all([
        usersAPI.deleteUser(userId)
    ]);
}

// aksoft use
export const updateUser = (userData) => async (dispatch) => {
    //console.log('updateUser userData', userData)
    const postData = {
        ...userData,
        shtani: Number(userData.shtani),
        birthday: '1900-01-01 12:00:00',
    }
    await Promise.all([
        usersAPI.updateUser(postData)
    ])
    dispatch(getCurrentSession())
}

export const updateUserPhoto = (formdata) => async dispatch => {
    let response = await usersAPI.updateUserPhoto(formdata);
    if (response.resultCode === 200) {
        dispatch(setAvatarUploading(false));
    }
}

export const uploadPhoto = (file) => async (dispatch) => { // file === formData with image file
    dispatch(setAvatarUploading(true));
    try {
        let response = await usersAPI.updateUserPhoto(file)
        if (response.resultCode === 200) {
            dispatch(updatePhoto(response.data));
            dispatch(setPhotoAlert('onChange'))
        } else {
            dispatch(setPhotoAlert('onError'));
        }
    } catch {
        dispatch(setPhotoAlert('onError'));
    } finally {
        dispatch(setAvatarUploading(false));
    }
}

export const deleteUserPhoto = (userId) => async (dispatch) => { //

    dispatch(setAvatarUploading(true));
    try {
        let response = await usersAPI.removePhoto(userId)
        if (response.resultCode === 200) {
            dispatch(clearPhoto());
            dispatch(setPhotoAlert('onDelete'))
        } else {
            dispatch(setPhotoAlert('onError'))
        }
    }
    catch {
        dispatch(setPhotoAlert('onError'))
    } finally {
        dispatch(setAvatarUploading(false));
    }

}

// export const getUserById = (id) => async (dispatch) => {

//     if(!id) return;

//     let response = await usersAPI.getUser(id);
//     if(response.resultCode === 200){

//         dispatch(setUserEditData(response.data));
//     }
// }

export default usersReducer;
