/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
//import { Dropdown } from "react-bootstrap";
//import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";
import { toAbsoluteUrl } from "../../../_helpers";
import {NavLink} from "react-router-dom";

export function MixedWidgetCustom2({ className }) {
  return (
      <>
        {/* begin::List Widget 14 */}
        <div className={`card card-custom ${className}`}>
          {/* begin::Header */}
          <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder text-dark">
              Список мероприятий
            </h3>
            {/*<div className="card-toolbar">
              <Dropdown className="dropdown-inline" alignRight>
                <Dropdown.Toggle
                    id="dropdown-toggle-top"
                    as={DropdownCustomToggler}
                >
                  <i className="ki ki-bold-more-ver" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <DropdownMenu1 />{" "}
                </Dropdown.Menu>
              </Dropdown>
            </div>*/}
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body pt-2">
            <div className="row">
            {/* begin::Item */}
            <div className="col-md-6">
            <div className="d-flex flex-wrap align-items-center mb-10">
              {/* begin::Symbol */}
              <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl(
                          "/media/bg/img-42.jpg"
                      )}')`,
                    }}
                ></div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                <NavLink
                    to="/dashboard/build"
                    className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                >
                  О материнских платах
                </NavLink>
                <span className="text-muted font-weight-bold font-size-sm my-1">
                Коротко о главном
              </span>
                <span className="text-muted font-weight-bold font-size-sm">
                Тип регистрации:{" "}
                  <span className="text-secondary font-weight-bold">Закрытая</span>
              </span>
              </div>
              {/* end::Title */}

              {/* begin::Info */}
              {/*<div className="d-flex align-items-center py-lg-0 py-2">
                <div className="d-flex flex-column text-right">
                <span className="text-dark-75 font-weight-bolder font-size-h4">
                  24,900
                </span>
                  <span className="text-muted font-size-sm font-weight-bolder">
                  просмотров
                </span>
                </div>
              </div>*/}
              {/* end::Info */}
            </div>
            </div>
            {/* end::Item */}

            {/* begin: Item */}
              <div className="col-md-6">
            <div className="d-flex flex-wrap align-items-center mb-10">
              {/* begin::Symbol */}
              <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url('${toAbsoluteUrl(
                          "/media/stock-600x400/img-45.jpg"
                      )}')`,
                    }}
                ></div>
              </div>
              {/* end::Symbol */}

              {/* begin::Title */}
              <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                >
                  Лучший компьютер
                </a>
                <span className="text-muted font-weight-bold font-size-sm my-1">
                Демонстрация пяти моделей ПК
              </span>
                <span className="text-muted font-weight-bold font-size-sm">
                Тип регистрации:{" "}
                  <span className="text-primary font-weight-bold">
                  Открытая
                </span>
              </span>
              </div>
              {/* end::Title */}

              {/* begin::Info */}
              {/*<div className="d-flex align-items-center py-lg-0 py-2">
                <div className="d-flex flex-column text-right">
                <span className="text-dark-75 font-weight-bolder font-size-h4">
                  70,380
                </span>
                  <span className="text-muted font-weight-bolder font-size-sm">
                  просмотров
                </span>
                </div>
              </div>*/}
              {/* end::Info */}
            </div>
              </div>
            {/* end: Item */}
          </div>
          {/* end::Body */}
          </div>
        </div>
        {/* end::List Widget 14 */}
      </>
  );
}
