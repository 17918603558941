// let offset = {
// 	initialLeft: 540,
// 	top: 0,
// 	left: 540
// }

export const createVideo = (myId, videoContainer, createObj, videoDestination, whoseVideo) => {
	if (!videoContainer[createObj.id]) {
		videoContainer[createObj.id] = { ...createObj };

		const roomContainer = videoDestination;
		const video = document.createElement('video');

		//0 for my video, 1 for incoming video
		if (whoseVideo === 0) {
			video.classList.add('internalVideo');
		}
		if (whoseVideo === 1) {
			video.classList.add('externalVideo');
		}

		video.srcObject = videoContainer[createObj.id].stream;
		video.id = `vid-${createObj.id}`;
		video.width = 250;
		video.height = 200;
		video.autoplay = true;
		video.controls = true;
		video.muted = false;

		const audio = document.createElement('audio');
		audio.style.display = 'none';
		audio.srcObject = videoContainer[createObj.id].stream;
		audio.id = `audio-${createObj.id}`;
		audio.autoplay = true;
		audio.controls = true;
		audio.muted = false;

		//initial styles for dragging
		// video.style.top = offset.top + 'px';
		// video.style.left = offset.left + 'px';


		//when there are 4 blocks on the screen - next video should go down
		// if (offset.left === offset.initialLeft * 4) {
		// 	offset.top += 215;
		// 	offset.left = 540;
		// } else {
		// 	//setting offset from left to video width + 15 offset
		// 	offset.left += 265;
		// }
		// if (myId === createObj.id) video.muted = true;

		// function dragElement(elmnt) {
		// 	let pos1 = 0,
		// 		pos2 = 0,
		// 		pos3 = 0,
		// 		pos4 = 0;

		// 	/* move the elem from anywhere inside the DIV:*/
		// 	elmnt.onmousedown = dragMouseDown;

		// 	function dragMouseDown(e) {
		// 		e = e || window.event;
		// 		// get the mouse cursor position at startup:
		// 		pos3 = e.clientX;
		// 		pos4 = e.clientY;
		// 		document.onmouseup = closeDragElement;
		// 		// call a function whenever the cursor moves:
		// 		document.onmousemove = elementDrag;
		// 	}

		// 	function elementDrag(e) {
		// 		e = e || window.event;
		// 		// calculate the new cursor position:
		// 		pos1 = pos3 - e.clientX;
		// 		pos2 = pos4 - e.clientY;
		// 		pos3 = e.clientX;
		// 		pos4 = e.clientY;
		// 		// set the element's new position:
		// 		elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
		// 		elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
		// 	}

		// 	function closeDragElement() {
		// 		/* stop moving when mouse button is released:*/
		// 		document.onmouseup = null;
		// 		document.onmousemove = null;
		// 	}
		// }

		// dragElement(video);

		roomContainer.append(audio);
		roomContainer.append(video);

		return video
	} else {
		return
	}
}