import { eventsAPI } from "../api/api";
import { getStringFromBool } from "../utils";

const SET_EVENTS = 'SET_EVENTS';
const SET_EDIT_EVENT = 'SET_EDIT_EVENT';
const CLEAR_EDIT_EVENT = 'CLEAR_EDIT_EVENT';
const SET_EVENT_BRANDING_KEYS = 'SET_EVENT_BRANDING_KEYS';
const CLEAR_EVENT_BRANDING_KEYS = 'CLEAR_EVENT_BRANDING_KEYS';
const InitialState = {
    entities: [],
    eventForEdit: {
        active: "0",
        date: "",
        description: "",
        name: "",
        private: "1"
    },
    eventUsers: null,
    eventReports: null,
    eventBranding: [null, null], // left = 0 element, right = 1 element
    totalCount: 0
}

const eventsReducer = (state = InitialState, action) => {
    switch (action.type){
        case SET_EVENTS:
            return { ...state, entities: [...action.events], totalCount: action.events.length }
        case SET_EVENT_BRANDING_KEYS:
            return {
                ...state,
                eventBranding:
                    [
                        action.payload.brending_keys[0] ? action.payload.brending_url + action.payload.brending_keys[0] : null, //left
                        action.payload.brending_keys[1] ? action.payload.brending_url + action.payload.brending_keys[1] : null //right
                    ]
            }
        case CLEAR_EVENT_BRANDING_KEYS:
            return { ...state, eventBranding: [null, null] }
        case CLEAR_EDIT_EVENT:
            return {
                ...state,
                eventForEdit: {
                    active: "0",
                    date: "",
                    description: "",
                    name: "",
                    private: "1"
                },
                eventUsers: null,
                eventReports: null,
                eventBranding: [null, null]
            }
        case SET_EDIT_EVENT:
        
            return { ...state,
                eventForEdit: {
                    active: getStringFromBool(action.payload.active), // replace boolean value to "0" or "1" to use in forms
                    date: action.payload.date,
                    description: action.payload.description,
                    name: action.payload.name,
                    private: getStringFromBool(action.payload.private) // replace boolean value to "0" or "1" to use in forms
                },
                    eventUsers: action.payload.users_events_relation,
                    eventReports: action.payload.events_reports_relation,
            }
        default:
            return state;
    }
}

const setEditEvent = (payload) => ({type: SET_EDIT_EVENT, payload});
export const setEventBrandingKeys = (payload) => ({type: SET_EVENT_BRANDING_KEYS, payload});
export const clearEventBrandingKeys = () => ({type: CLEAR_EVENT_BRANDING_KEYS});

const setEvents = (events) => ({ type: SET_EVENTS, events });
export const clearEditEvent = () => ({ type: CLEAR_EDIT_EVENT});

export const requestEvents = () => async (dispatch) => {
    await Promise.all([   
        eventsAPI.getAllEvents().then(r => {
            if(r.resultCode === 200){
                dispatch(setEvents(r.data));
            }
        })
]);
}

export const clearEventFields = () => async (dispatch) => {
    await Promise.all([
        dispatch(clearEditEvent())
    ]);
}


export const createEvent = (event) => async () => {
    await Promise.all([
        eventsAPI.addEvent(event)
    ]);
}

export const updateEvent = (event) => async () => {
    await Promise.all([
        eventsAPI.updateEvent(event)
    ]);
}

export const deleteEvent = (id) => async () => {
    await Promise.all([
        eventsAPI.deleteEvent(id)
    ]);
}

export const setActiveEvent = (id) => async () => {
    await Promise.all([
        eventsAPI.setActiveEvent(id)
    ]);
}

export const updateEventsUsers = (eventId, usersIds) => async () => {
    await Promise.all([
        eventsAPI.updateEventUsers(eventId, usersIds)
    ]);
} 

export const updateEventsReports = (eventId, reportsIds) => async () => {
    await Promise.all([
        eventsAPI.updateEventReports(eventId, reportsIds)
    ]);
} 

export const getEventById = (id) => async (dispatch) => {

    if(!id) return;

    let response = await eventsAPI.getEvent(id);
    if(response.resultCode === 200){
        dispatch(setEditEvent(response.data));
    }
}

export const getEventBranding = (id) => async (dispatch) => {
    if(!id) return;

    let response = await eventsAPI.getEvent(id);
    if(response.resultCode === 200){
        dispatch(setEventBrandingKeys(response.data))
    }
}

export default eventsReducer;
