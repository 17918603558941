/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};


export function PasswordInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  const [visible, setVisibility] = useState(false);
  const InputType = visible ? "text" : "password";
  return (
    <>
      {label && <label>{/*Введите*/}{label}</label>}
      <div className="input-group">
      <input
        type={InputType}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      <div className="input-group-append" style={{ height: "calc(1.5em + 1.65rem + 2px)"}}>
            <a
                title="Показать/скрыть пароль"
                className="btn btn-icon btn-light btn-hover-secondary"
                style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                onClick={() => setVisibility(Visibility => !Visibility)}
            >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className={visible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                    </span>
            </a>
      </div>
        {withFeedbackLabel && (
            <FieldFeedbackLabel
                error={errors[field.name]}
                touched={touched[field.name]}
                label={label}
                type={type}
                customFeedbackLabel={customFeedbackLabel}
            />
        )}
      </div>
    </>
  );
}
