import React from 'react';
import XLSX from 'xlsx';
import moment from "moment";

export const ExportBlock = ({ dataArray, dataHeaders, dataRequest, title }) => {
  function exportFile(data, format) { // XLSX native export function
    var worksheet = XLSX.utils.aoa_to_sheet(data);
    var new_workbook = XLSX.utils.book_new();
    var date = new Date();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "export");
    XLSX.writeFile(new_workbook, `${title} - ${moment(date).format('[Дата] DD-MM-YYYY [Время] HH-mm-ss')}.${format}`);
  }

  async function exportSheet(format) { // Export data from request or from static data
    if (dataRequest && !dataArray) {
      const data = await dataRequest();
      if (data) {
        exportFile([dataHeaders, ...data], format)
      } else {
        // error handling
      }
    } else if (dataArray) {
      exportFile([dataHeaders, ...dataArray], format)
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <button className="btn btn-light btn-hover-primary btn-sm mx-2" onClick={() => exportSheet("csv")}><i className="fa fa-download icon-1x" /><span className="">CSV</span></button>
      <button className="btn btn-light btn-hover-primary btn-sm mx-2" onClick={() => exportSheet("xlsx")}><i className="fa fa-download icon-1x" /><span className="">XLSX</span></button>
    </div>
  )
}
