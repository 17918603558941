import React, { useEffect, useRef } from 'react'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/_helpers';

export const VisitCard = ({ userPhone, userEmail, userCompany, userPosition, userLastName, userName, userSecondName, canvasOnClick, id }) => {
	const canvas = useRef(null)
	useEffect(() => {
		if (canvas.current) {
			let phoneIcon = new Image();
			let envelopeIcon = new Image();
			let userIcon = new Image();

			let context = canvas.current.getContext("2d");

			phoneIcon.onload = function () {
				context.drawImage(phoneIcon, 10, 45, 24, 24)
			};
			envelopeIcon.onload = function () {
				context.drawImage(envelopeIcon, 10, 70, 24, 24)
			};
			userIcon.onload = function () {
				context.drawImage(userIcon, 10, 95, 24, 24)
			};
			phoneIcon.src = toAbsoluteUrl("/media/svg/icons/Phone/Phone.svg");
			envelopeIcon.src = toAbsoluteUrl("/media/svg/icons/Envelope/Email.svg");
			userIcon.src = toAbsoluteUrl("/media/svg/icons/User/Company.svg");

			context.fillStyle = "white";
			// context.strokeRect(0, 0, canvas.width, canvas.height)
			context.fillRect(0, 0, canvas.width, canvas.height);
			context.fillStyle = '#00AFBC';
			context.font = '1.5em Roboto bold';
			context.fillText(`${userLastName} ${userName} ${userSecondName}`, 10, 25);
			context.font = '1em Roboto';
			context.fillStyle = '#70899F';
			context.fillText(`+${userPhone}`, 50, 60);
			context.fillStyle = '#70899F';
			context.font = '1em Roboto';
			context.fillText(`${userEmail}`, 50, 85);

			if (userCompany.length > 25) {
				let firstPart = userCompany.substring(0, 25);
				let secondPart = userCompany.substring(25);
				context.fillStyle = '#70899F';
				context.font = '1em Roboto';
				context.fillText(`${firstPart}`, 50, 110);
				context.fillStyle = '#70899F';
				context.font = '1em Roboto';
				context.fillText(`${secondPart}`, 50, 125);
				context.fillStyle = '#70899F';
				context.font = '.75em Roboto';
				context.fillText(`${userPosition}`, 50, 140);
			} else {
				context.fillStyle = '#70899F';
				context.font = '1em Roboto';
				context.fillText(`${userCompany}`, 50, 110);
				context.fillStyle = '#70899F';
				context.font = '.75em Roboto';
				context.fillText(`${userPosition}`, 50, 125);
			}
		}
	}, [canvas])

	return (
		<div>
			<canvas ref={canvas} onClick={canvasOnClick} id={`drawingCanvas-${id}`} style={{ width: '300px', height: '150px', border: '2px solid #40A9D6', borderRadius: '10px', backgroundColor: '#fff', cursor: 'pointer' }}></canvas>
		</div>
	)
}


