import React from 'react';
import Peer from 'peerjs';
import { mainServerRoot, pathForPeer } from '../../socket/constants';


export const initializePeerConnection = (userUUID, sessionToken) => {
	const peerID = `${userUUID}_${sessionToken.split('-')[0]}`;
	return new Peer(peerID, {
		host: mainServerRoot,
		debug: 1,
		port: 10011,
		path: pathForPeer,
		secure: true,
		config: {
			'iceServers': [
				{ 'urls': 'stun:stun.l.google.com:19302' },
				{ 'urls': 'stun:stun.services.mozilla.com' },
				// { 'urls': 'turn:numb.viagenie.ca', 'credential': 'muazkh', 'username': 'webrtc@live.com' },
				{
					"urls": [
						"turn:13.250.13.83:3478?transport=udp"
					],
					"username": "YzYNCouZM1mhqhmseWk6",
					"credential": "YzYNCouZM1mhqhmseWk6"
				}
			]
		}
	});
}