import React from 'react';

const ClientTwo = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdERAm2DixgfYbugpbTvqr46t6nm2Ebc4e62m-vZGsx7DjsdQ/viewform?embedded=true"
                width="100%" height="1642" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default ClientTwo;
