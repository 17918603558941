import { ax, getToken } from "./api-setup";

//Constants

export const ADD_TYPE = "api/addaccesslisttype";
export const ADD_TO_LIST = "api/addtoaccesslists";
export const GET_ALL_TYPES = "api/getaccessliststypes";
export const GET_LIST = "api/getaccesslist";
export const DELETE_TYPE = "api/deleteaccesslisttype";
export const DELETE_FROM_LIST = "api/deletefromaccesslist";


//Methods

export const blackListsApi = {
    addListType(name) { //Admin permissions
        return ax.put(`${ADD_TYPE}/${getToken()}`, {type_name: name}).then(r => r.data); 
    },
    addToList(type, value) {
        return ax.put(`${ADD_TO_LIST}/${getToken()}`, {type: type, value: value}).then(r => r.data); 
    },
    getAllTypes() {
        return ax.get(`${GET_ALL_TYPES}/${getToken()}`).then(r => r.data); 
    },
    getList(type) {
        return ax.get(`${GET_LIST}/${getToken()}/${type}`).then(r => r.data); 
    },
    deleteType(type) {
        return ax.delete(`${DELETE_TYPE}/${getToken()}`, {id: type}).then(r => r.data); 
    },
    deleteValue(val) {
        return ax.delete(`${DELETE_FROM_LIST}/${getToken()}`,{data:
        { 
            value: val
        }
    }).then(r => r.data); 
    },
}