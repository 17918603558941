import React from 'react';

const PoolMain = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSfv-FhvI6NeS8p-gibn94rmP6kpOtWmQvqHUwV8b4eKxpALdw/viewform?embedded=true"
                width="100%" height="1450" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default PoolMain;
