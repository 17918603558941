
import { getBoolFromString } from "../utils";
import { ax, getToken } from "./api-setup";

// Constants

export const GET_ALL_EVENTS = 'api/getallevents';
export const ADD_EVENT = 'api/addevent';
export const UPDATE_EVENT = 'api/updateeventinfo';
export const GET_EVENT = 'api/getevent';
export const DELETE_EVENT = 'api/delevent';
export const UPDATE_EVENT_USERS = 'api/updateeventusers';
export const UPDATE_EVENT_REPORTS = 'api/updateeventreports';
export const SET_ACTIVE_EVENT = 'api/makeeventactive';
export const UPDATE_BRANDING = 'api/updatebrending';

// Methods

export const eventsAPI = {
    getAllEvents() {
        return ax.get(`${GET_ALL_EVENTS}/${getToken()}`).then(response => response.data);
    },
    addEvent(event) {
        return ax.post(`${ADD_EVENT}/${getToken()}`, { ...event, private: getBoolFromString(event.private) })
    },
    updateEvent(event) {
        return ax.put(`${UPDATE_EVENT}/${getToken()}`, { ...event, private: getBoolFromString(event.private) })
    },
    updateEventUsers(eventId, usersIds) {
        return ax.put(`${UPDATE_EVENT_USERS}/${getToken()}`, {
            obj_id: parseInt(eventId),
            users_ids: [...usersIds]
        })
    },
    updateEventReports(reportId, reportsIds) {
        return ax.put(`${UPDATE_EVENT_REPORTS}/${getToken()}`, {
            obj_id: parseInt(reportId),
            reports_ids: [...reportsIds]
        })
    },
    updateBranding(formData) {
        return ax.post(`${UPDATE_BRANDING}/${getToken()}`, formData)
            .then(response => response.data);
    },
    getEvent(id) {
        return ax.post(`${GET_EVENT}/${getToken()}`, { obj_id: id }).then(response => response.data);
    },
    setActiveEvent(id) {
        return ax.put(`${SET_ACTIVE_EVENT}/${getToken()}`, { obj_id: id }).then(response => response.data);
    },
    deleteEvent(id) {
        return ax.delete(`${DELETE_EVENT}/${getToken()}`, {
            data: {
                obj_id: id
            }
        })
    },
}
