import React, { useContext, useEffect, useRef } from "react";
import { Tooltip } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from '@mui/icons-material/MicOff';
import IconButton from "@material-ui/core/IconButton";
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import "./ChatControlButtons.css";
import { Box } from "@mui/system";
import { VoiceChatButtonsContext } from "../../../../utils/Context";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CustomizedButtons } from "../chat-bar/tab-panel/tab-panel-element/UsersTabPanel";
import Modal from '@mui/material/Modal';
import { Selects } from "../../../../utils/audioVideoContent/AudioVideoInitiator";
import SettingsIcon from '@mui/icons-material/Settings';
import { conferenceHalls } from "../../../../utils/zonesData/ConferenceHalls";

const themeCustom = createMuiTheme({
	components: {
		MuiSwitch: {
			styleOverrides: {
				switchBase: {
					// Controls default (unchecked) color for the thumb
					color: "#ccc",
					'&#checked': {
						color: '#00AFBC'
					}
				},
				colorSecondary: {
					"&$checked": {
						// Controls checked color for the thumb
						color: "#f2ff00"
					}
				},
				track: {
					// Controls default (unchecked) color for the track
					opacity: 0.2,
					backgroundColor: "#fff",
					"$checked$checked + &": {
						// Controls checked color for the track
						opacity: 0.7,
						backgroundColor: "#fff"
					}
				}
			}
		}
	}
});

const useStyles = makeStyles(themeCustom => ({
	icon: {
		height: '1em',
		width: '1em'
	},

}));

const style = {
	position: 'absolute',
	top: '50%',
	left: '20%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: '#FFFFFF',
	borderRadius: '8px',
	boxShadow: 24,
	p: 4,
	paddingBottom: '10px',
};

export default function ChatControlButtons() {

	const { isAudio, setIsAudio, isVideo, setIsVideo, isScreen, startCapture, stopCapture, checked, setChecked, setModalIsVisible, modalIsVisible, audioDevice, videoDevice, setAudioSelectValue, setVideoSelectValue, initializePeerAndSockets, closeConnections, audioSelectValue, videoSelectValue, audioOutputDevice, audioOutputSelectValue, setAudioOutputSelectValue, currentRoom, connectedUsers, mutedAutomatically, roomConnected } = useContext(VoiceChatButtonsContext);

	useEffect(() => {
		if (roomConnected && conferenceHalls.includes(roomConnected.split('_')[1]) && connectedUsers[0].userRole === 3 && mutedAutomatically) {
			stopAudio();
			stopVideo();
			stopCapture();
		}
	}, [roomConnected, mutedAutomatically])

	const startAudio = () => {
		setIsAudio(true)
	};

	const stopAudio = () => {
		setIsAudio(false);
	}
	const startVideo = () => {
		setIsVideo(true)
	};

	const stopVideo = () => {
		setIsVideo(false);
	}

	const handleChange = () => {
		setChecked(!checked);
	};

	const openModal = () => {
		setModalIsVisible(true)
	}

	const closeModal = () => {
		setModalIsVisible(false)
	}

	const saveSettings = () => {
		closeConnections();
		initializePeerAndSockets();
		setModalIsVisible(false);
	}

	const classes = useStyles();

	return (
		<ThemeProvider theme={themeCustom}>
			<>
				<Box sx={{
					marginLeft: '.5em',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					position: 'absolute',
					right: 0,
					bottom: '10px'
				}}
				>
					{!modalIsVisible ?
						<Tooltip title={'Открыть настройки ввода'} placement='top-start'>
							<IconButton onClick={openModal}
								style={{ position: 'absolute', bottom: 0, left: '-105px' }}>
								<SettingsIcon style={{ width: '1em', height: '1em', color: '#B5B5C3', }} />
							</IconButton>
						</Tooltip>
						:
						<Tooltip title={'Закрыть настройки ввода'} placement='top-start'>
							<IconButton onClick={openModal}
								style={{ position: 'absolute', bottom: 0, left: '-105px' }}>
								<SettingsIcon style={{ width: '1em', height: '1em', color: '#00AFBC', }} />
							</IconButton>
						</Tooltip>
					}

					{modalIsVisible &&
						<Modal
							open={modalIsVisible}
							onClose={closeModal}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={style}>
								<Selects
									audioDevice={audioDevice}
									videoDevice={videoDevice}
									setAudioSelectValue={setAudioSelectValue}
									setVideoSelectValue={setVideoSelectValue}
									audioSelectValue={audioSelectValue}
									videoSelectValue={videoSelectValue}
									audioOutputDevice={audioOutputDevice}
									audioOutputSelectValue={audioOutputSelectValue}
									setAudioOutputSelectValue={setAudioOutputSelectValue}
								/>
								<CustomizedButtons
									btnOneText={'Отмена'}
									btnTwoText={'Сохранить'}
									btnOneCallback={closeModal}
									btnTwoCallback={saveSettings}
								/>
							</Box>
						</Modal>
					}
					<FormControlLabel
						sx={{
							margin: 'auto'
						}}
						control={
							<Switch
								checked={checked}
								onChange={handleChange}
							/>}
						label={checked ? 'Hide videos' : "Show videos"}
						size='small'
					/>
					{!isScreen ?
						<Tooltip title={'Демонстрация экрана отключена'} placement='top-start'>
							<IconButton onClick={startCapture}
								disabled={roomConnected && conferenceHalls.includes(roomConnected.split('_')[1]) && connectedUsers[0].userRole === 3 && mutedAutomatically}>
								<DesktopAccessDisabledIcon style={{ width: '.8em', height: '.8em', color: '#FF94A1' }} />
							</IconButton>
						</Tooltip>
						:
						<Tooltip title={'Демонстрация экрана включена'} placement='top-start'>
							<IconButton onClick={stopCapture}>
								<DesktopWindowsIcon style={{ width: '.8em', height: '.8em', color: '#B5B5C3' }} />
							</IconButton>
						</Tooltip>
					}
					{!isVideo ?
						<Tooltip title={'Видео выключено'} placement='top-start'>
							<IconButton onClick={startVideo}
								disabled={roomConnected && conferenceHalls.includes(roomConnected.split('_')[1]) && connectedUsers[0].userRole === 3 && mutedAutomatically}>
								<VideocamOffIcon className={classes.icon} style={{ color: '#FF94A1' }} />
							</IconButton>
						</Tooltip>
						:
						<Tooltip title={'Видео включено'} placement='top-start'>
							<IconButton onClick={stopVideo}>
								<VideocamIcon className={classes.icon} style={{ color: '#B5B5C3' }} />
							</IconButton>
						</Tooltip>}
					{!isAudio ?
						<Tooltip title={'Звук выключен'} placement='top-start'
							disabled={roomConnected && conferenceHalls.includes(roomConnected.split('_')[1]) && connectedUsers[0].userRole === 3 && mutedAutomatically}
						>
							<IconButton onClick={startAudio}>
								<MicOffIcon className={classes.icon} style={{ color: '#FF94A1' }} />
							</IconButton>
						</Tooltip>
						:
						<Tooltip title={'Звук включён'} placement='top-start'>
							<IconButton onClick={stopAudio}			>
								<MicIcon className={classes.icon} style={{ color: '#B5B5C3' }} />
							</IconButton>
						</Tooltip>
					}
				</Box >
			</>
		</ThemeProvider >
	);
}
