/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
//import axios from "axios";
//import * as _redux from "./redux";
import store from "./redux/redux-store";
import App from "./app/App";
import "./index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

// let pass = prompt("Введите пароль для доступа на сайт");

// if (pass === 'dfgl24Os2mdksi;35') {
//   ReactDOM.render(
//     <MetronicI18nProvider>
//       <MetronicLayoutProvider>
//         <MetronicSubheaderProvider>
//           <App store={store} /*persistor={persistor}*/ basename={PUBLIC_URL} />
//         </MetronicSubheaderProvider>
//       </MetronicLayoutProvider>
//     </MetronicI18nProvider>,
//     document.getElementById("root")
//   );
// } else {
//   ReactDOM.render(
//     <div>У Вас нет доступа к сайту</div>,
//     document.getElementById("root")
//   );
// }

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <App store={store} /*persistor={persistor}*/ basename={PUBLIC_URL} />
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);

