import React, { useContext, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { makeStyles } from '@material-ui/styles';
import Popover from '@mui/material/Popover';
import { MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { VoiceChatButtonsContext } from '../../../../../../utils/Context';
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { timeFormatHelper } from '../../../../../../utils/TimeFormatter';

const drawerWidth = 360;

const BootstrapButton = styled(Button)({
	width: '30%',
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 12,
	padding: '5px 5px',
	border: 'none',
	color: '#fff',
	lineHeight: 1.5,
	borderRadius: '20px',
	backgroundColor: '#00AFBC',
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		backgroundColor: '#F1F9FA',
		color: '#00AFBC',
		borderColor: '#09d4e3',
		boxShadow: 'none',
	},
	'&:active': {
		boxShadow: 'none',
		color: '#00AFBC',
		backgroundColor: '#F1F9FA',
		borderColor: '#09d4e3',
	},
	'&:focus': {
		boxShadow: '0 0 0 0.2rem rgba(9,212,227, 0.8)',
	},
});
const ColorButton = styled(Button)({
	width: '30%',
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 12,
	padding: '5px 5px',
	border: 'none',
	color: '#00AFBC',
	lineHeight: 1.5,
	borderRadius: '20px',
	backgroundColor: '#F1F9FA',
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		backgroundColor: '#00AFBC',
		color: '#fff',
		borderColor: '#09d4e3',
		boxShadow: 'none',
	},
	'&:active': {
		boxShadow: 'none',
		color: '#fff',
		backgroundColor: '#00AFBC',
		borderColor: '#09d4e3',
	},
	'&:focus': {
		boxShadow: '0 0 0 0.2rem rgba(9,212,227, 0.8)',
	},
});

export function CustomizedButtons({ btnOneText, btnTwoText, btnOneCallback, btnTwoCallback }) {
	return (
		<Stack spacing={2} direction="row" style={{ justifyContent: 'flex-end', width: '100%' }}>
			<ColorButton variant="contained" onClick={btnOneCallback}>{btnOneText}</ColorButton>
			<BootstrapButton variant="contained" onClick={btnTwoCallback}>
				{btnTwoText}
			</BootstrapButton>
		</Stack>
	);
}

const useStyles = makeStyles({
	speaker: {
		backgroundColor: '#fff',
		color: '#262626',
		border: 'none',
		'&:hover': {
			backgroundColor: '#F1F9FA !important',
			border: 'none',
			boxShadow: 'none',
		},
		'&:active': {
			boxShadow: 'none',
			backgroundColor: '#F1F9FA',
			border: 'none',
		},
		'&:focus': {
			boxShadow: 'none',
			backgroundColor: '#F1F9FA',
			border: 'none',
		},
	},
});

const style = {
	position: 'absolute',
	top: '30%',
	left: '45%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: '#FFFFFF',
	borderRadius: '8px',
	boxShadow: 24,
	padding: '18px',
	paddingBottom: '10px',
};

const textAreaStyle = {
	width: '100%',
	marginBottom: '5px',
	border: '1px solid lightgrey',
	borderRadius: '4px',
	outline: 'none',
	resize: 'none'
}

export const UsersTabPanel = () => {

	const { connectedUsers, socket, personalMessages, setPersonalMessages } = useContext(VoiceChatButtonsContext);
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [recieverPeerUUID, setRecieverPeerUUID] = useState();
	const [recieverData, setRecieverData] = useState();
	const [message, setMessage] = useState('')
	const messageBox = useRef();

	const handleOpenModal = () => {
		setOpenModal(true);
		handleClose();
	}

	const handleCloseModal = () => setOpenModal(false);

	const sendPrivatMessage = () => {
		if (message) {
			let keyName = `${recieverData}_${recieverPeerUUID}`;

			if ((keyName in personalMessages) === true) {
				setPersonalMessages(personalMessages => ({
					...personalMessages, ...personalMessages[keyName].push({
						me: true,
						currentMinutes: timeFormatHelper(new Date().getMinutes()),
						currentHour: timeFormatHelper(new Date().getHours()),
						message: message,
						name: connectedUsers[0].userName,
						lastName: connectedUsers[0].userLastName
					})
				}))
			} else {
				setPersonalMessages(personalMessages => ({
					...personalMessages, [keyName]: [{
						me: true,
						currentMinutes: timeFormatHelper(new Date().getMinutes()),
						currentHour: timeFormatHelper(new Date().getHours()),
						message: message,
						name: connectedUsers[0].userName,
						lastName: connectedUsers[0].userLastName
					}]
				}))
			}

			socket.emit('sendTo', {
				packet: {
					pmess: {
						message: message,
						name: connectedUsers[0].userName,
						last_name: connectedUsers[0].userLastName
					}
				},
				peerUUID: recieverPeerUUID
			});
			setMessage('');
			messageBox.current.value = '';
			handleCloseModal();
		}
	}

	const sendVisitCard = () => {
		socket.emit('sendTo', {
			packet: {
				vcard: {
					isCard: true,
					userPhone: connectedUsers[0].userPhone,
					userEmail: connectedUsers[0].userEmail,
					userCompany: connectedUsers[0].userCompany,
					userPosition: connectedUsers[0].userPosition,
					userLastName: connectedUsers[0].userLastName,
					userName: connectedUsers[0].userName,
					userSecondName: connectedUsers[0].userSecondName
				}
			},
			peerUUID: recieverPeerUUID
		})
		setAnchorEl(null);
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleClick = (peerUUID, data, e) => {
		setAnchorEl(e.currentTarget);
		setRecieverPeerUUID(peerUUID);
		setRecieverData(data);
	};

	const privatlyCallPeer = () => {
		handleClose();
		setTimeout(() => {
			socket.emit('callPeer', anchorEl.id)
		}, 1000);
	}

	return (
		<>
			<Drawer
				sx={{
					position: 'relative',
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						height: '67vh',
						left: `717px`,
						boxSizing: 'border-box',
					},
					'& .MuiDrawer-paper::-webkit-scrollbar': {
						width: '0px',
						background: 'transparent',
					},
				}}
				variant="permanent"
				anchor="left"
			>
				<Modal
					open={openModal}
					onClose={handleCloseModal}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography style={{ fontSize: '1.2em' }}>
							Отправить сообщение для {recieverData}
						</Typography>
						<Divider style={{ margin: '10px 0' }} />
						<TextareaAutosize
							style={textAreaStyle}
							minRows={2}
							maxRows={5}
							aria-label="maximum height"
							placeholder="Введите своё сообщение"
							defaultValue=""
							ref={messageBox}
							onChange={e => setMessage(e.target.value)}
						/>
						<CustomizedButtons
							btnOneText={'Отмена'}
							btnTwoText={'Отправить'}
							btnOneCallback={handleCloseModal}
							btnTwoCallback={sendPrivatMessage}
						/>
					</Box>
				</Modal>
				<Divider />
				<List sx={{ marginTop: '0' }}>
					{connectedUsers.map((user, index) => (
						<ListItem
							className={classes.speaker}
							key={index}
							disablePadding
						>
							<ListItemButton
								id={user.peerUUID}
								className={classes.speaker}
								onClick={index !== 0 ? (e) => handleClick(user.peerUUID, `${user.userLastName} ${user.userName}`, e) : null}>
								<ListItemText
									aria-describedby={id}
									primary={`${user.userLastName} ${user.userName}`}
									secondary={user.userRole === 0 ? 'Суперадмин' : user.userRole === 1 ? 'Администратор' : user.userRole === 2 ? 'Спикер' : null}
								/>
							</ListItemButton>
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								PaperProps={{
									style: {
										border: '1px solid #d5f2f5',
										boxShadow: 'none',
										borderRadius: '8px',
										backgroundColor: '#fff',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-start',
										alignItems: 'flex-start',
									}
								}}
								anchorPosition={{ top: 120, left: 700 }}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'center',
									horizontal: 'right',
								}}
							>
								<MenuItem
									type='button'
									sx={{
										padding: '6px 16px !important',
										textAlign: 'left',
										width: '100%'
									}}
									onClick={handleOpenModal}
								>Написать личное сообщение
								</MenuItem>
								<MenuItem
									type='button'
									sx={{
										padding: '6px 16px !important',
										textAlign: 'left',
										width: '100%'
									}}
									onClick={sendVisitCard}
								>Отправить визитку
								</MenuItem>
							</Popover>
						</ListItem>
					))}
				</List>
			</Drawer>
			<Box sx={{
				width: drawerWidth - 1,
				display: 'flex',
				position: 'absolute',
				bottom: '2%',
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}>
				{/* <CustomizedButtons /> */}
			</Box>
		</>
	)
}