/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";



const CustomPasswordInput = ({placeholder, getInputClasses, InputClass, formikProps, touched, errors}) => {
    const [visible, setVisibility] = useState(false);
    const InputType = visible ? "text" : "password";
    const icon = (
        <div className="input-group-append" style={{ height: "calc(1.5em + 1.65rem + 2px)"}}>
            <a
                title="Показать/скрыть пароль"
                className="btn btn-icon btn-light btn-hover-secondary"
                style={{ borderTopRightRadius: "0.42rem", borderBottomRightRadius: "0.42rem" }}
                onClick={() => setVisibility(Visibility => !Visibility)}
            >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className={visible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                    </span>
            </a>
        </div>
    );

    return (
        <>
        <input
            type={InputType}
            placeholder={placeholder}
            className={getInputClasses}
            {...formikProps}
        />
    {icon}
            {touched && errors ? (
                <div className="invalid-feedback">{errors}</div>
            ) : null}
        </>

)
}

export default CustomPasswordInput;
