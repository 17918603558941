import { videosAPI } from "../api/api";

const SET_VIDEOS = 'SET_VIDEOS';
const SET_LOADING = 'SET_LOADING';

const InitialState = {
    entities: [],
    cdnLink: "",
    isLoading: false
}

const videosReducer = (state = InitialState, action) => {
    switch (action.type){
        case SET_VIDEOS:
            const videos = [...action.payload];
            videos.shift();
            return { ...state, cdnLink: action.payload[0], entities: videos}
        case SET_LOADING:
            return { ...state, isLoading: action.payload }
        default:
            return state;
    }
}


const setVideos = (payload) => ({type: SET_VIDEOS, payload});
const setLoading = (payload) => ({type: SET_LOADING, payload}) //payload = true | false


export const requestVideos = () => async (dispatch) => {
    dispatch(setLoading(true));
    const response = await videosAPI.getVideos();
    if(response.resultCode === 200){
        dispatch(setVideos(response.data))
    }
    dispatch(setLoading(false));
}

export const deleteVideo = (key) => async (dispatch) => {
    dispatch(setLoading(true));
    const response = await videosAPI.deleteVideo(key);
    if(response.resultCode === 200){
        dispatch(requestVideos())
    } else {
        // error handling
        dispatch(setLoading(false));        
    }
}

export const addVideo = (file) => async (dispatch) => {
    dispatch(setLoading(true))
    const response = await videosAPI.uploadVideo(file)
    if(response.resultCode === 200){
        dispatch(requestVideos())
    } else {
        // error handling
        dispatch(setLoading(false));        
    }
}

export default videosReducer;