import React from 'react';

const ClientFive = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSeprBFyCrC-By2UnH_nT9geqSHNSqGo4LN1x6MJU2qg9Sz4yg/viewform?embedded=true"
                width="100%" height="1721" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default ClientFive;
