import React from 'react';

const ClientOne = () => {
    return (
        <div className="card card-custom">
            <iframe
                scrolling="no"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdUCUoxM3MYi1ZbyPgHlCiVoo1ob4TvijB39iQEP6-KUDGa1Q/viewform?embedded=true"
                width="100%" height="1615" frameBorder="0" marginHeight="0" marginWidth="0">Загрузка…
            </iframe>
        </div>
    );
};

export default ClientOne;
